import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from 'App';
import 'bootstrap/dist/css/bootstrap.min.css';
// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import Main from "Main";
import { Provider as AuthProvider } from './context/AuthContext';
import { ThemeProvider } from "@mui/material/styles";
import themeDark from "assets/theme-dark";
import theme from "assets/theme";


ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
    <AuthProvider>
    <ThemeProvider theme={theme}>
        <Main />
        </ThemeProvider>
        </AuthProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
