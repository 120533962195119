export const emailValidator = (email) => {
    const re = /\S+@\S+\.\S+/;
    if (!email || email.length <= 0) {
        return 'Email cannot be empty.';
    }
    if (!re.test(email)) {
        return 'Ooops! We need a valid email address.';
    }
    return '';
};

export const passwordValidator = (password) => {
    // const re = /^(?=(?:[^A-Z]*[A-Z]){1,}(?![^A-Z]*[A-Z]))(?=(?:[^0-9]*[0-9]){2,}(?![^0-9]*[0-9]))(?=(?:[^a-z]*[a-z]){1,}(?![^a-z]*[a-z]))[A-Za-z0-9][^'=-]{8,}$/;
    if (!password || password.length <= 0) {
        return 'Password cannot be empty.';
    }
    if (password.length < 6) {
        return 'Password must be atleast 6 characters long';
    }
    if (password.length > 25) {
        return "Password can't be more than 25 characters";
    }

    // if (!re.test(password)) {
    //     return "Password must be atleast 8 characters long, must contain atleast one uppercase and one lowercase characters.It must not contain these characters (=,-,').Also, it must contain atleast 2 numeric characters.";
    // }

    return '';
};

export const phoneValidator = (num) => {
    if (!num || num.length <= 0) {
        return 'Phone number cannot be empty.';
    }
    if (!Number(num)) {
        return 'Please enter valid phone numbers';
    }
    if (num.length < 10) {
        return 'Phone number must be 10 digit.';
    }
    return '';
}


export const countryCodeValidator = (name) => {
    if (!name || name.length <= 0) {
        return 'Country Code cannot be empty.';
    }
    if (!/(\+\d{1,3})/g.test(name)) {
        return 'Only + and Number are allowed';
    }
    return '';
}

export const nameValidator = (name) => {
    if (!name || name.length <= 0) {
        return 'Name cannot be empty.';
    }
    if (!/^[a-zA-Z ]*$/g.test(name)) {
        return 'Only alphabets are allowed';
    }
    return '';
}

export const seriesNameValidator = (name) => {
    const regex = /^[ A-Za-z0-9-_@/()]*$/;
    if (!name || name.length <= 0) {
        return 'Series Name cannot be empty.';
    }
    if (!regex.test(name)) {
        return 'Series name contain any of the following characters: @,-,/,(,_,)';
    }
    return '';
}

export const impressionValidator = (val) => {
    if (!val || val.length <= 0) {
        return 'Impression cannot be empty.';
    }
    if (val.length > 25) {
        return "Impression can't be more than 25 characters";
    }
    return '';
}

export const outlayValidator = (val) => {
    if (!val || val.length <= 0) {
        return 'Outlay cannot be empty.';
    }
    if (val.length > 25) {
        return "Outlay can't be more than 25 characters";
    }
    return '';
}

export const lineItemNameValidator = (name) => {
    const regex = /^[ A-Za-z0-9-_@/()]*$/;
    if (!name || name.length <= 0) {
        return 'line item name cannot be empty.';
    }
    if (name.length < 6) {
        return "line item name can't be less than 6 characters";
    }
    if (name.length > 30) {
        return "line item name can't be more than 30 characters";
    }
    if (!regex.test(name)) {
        return 'line item name contain any of the following characters: @,-,/,(,_,)';
    }
    return '';
}

export const brandNameValidator = (name) => {
    const regex = /^[ A-Za-z0-9-_&'!]*$/;
    if (!name || name.length <= 0) {
        return 'Brand name cannot be empty.';
    }
    if (!regex.test(name)) {
        return "Brand name contain any of the following characters: - _ & ' ! ";
    }
    return '';
}

export const campaignTitleValidator = (name) => {
    if (!name || name.length <= 0) {
        return 'Campaign Title cannot be empty.';
    }
    if (!/^[a-zA-Z ]*$/g.test(name)) {
        return 'Only alphabets are allowed';
    }
    return '';
}

export const headlineValidator = (name) => {
    if (!name || name.length <= 0) {
        return 'Headline cannot be empty.';
    }
    if (!/^[a-zA-Z ]*$/g.test(name)) {
        return 'Only alphabets are allowed';
    }
    return '';
}

export const URLvalidator = (url) => {
    var regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if(!regex .test(url)) {
        return 'Please enter a valid url';
    }
}

export const alphaNumericValidator = (val, name) => {
    const regex = /^[ A-Za-z0-9-#$%&*'.+_:/=]*$/;
    if (!val || val.length <= 0) {
        return `${name} cannot be empty.`;
    }
    if (!regex.test(val)) {
        return `${name} contain any of the following characters: - # $ % & * ' . + _ : / =`;
    }
    return null;
}