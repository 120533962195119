import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import DataTable from "common/Tables/DataTable";

// Data
import data from "layouts/dashboard/components/Projects/data";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box py={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <Box mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="leaderboard"
                title="Plans Created"
                count={281}
                percentage={{
                  label: "Just updated",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Box mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Total Brands"
                count="2,300"
                percentage={{
                  label: "Just updated",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Box mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="leaderboard"
                title="Total Series"
                count="34k"
                percentage={{
                  label: "Just updated",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Box mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Users"
                count="3"
                percentage={{
                  label: "Just updated",
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Box mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={7}>
              <Card>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={2} className="dashboard-table-head">
                  <MDBox className="dashboard-table-box">
                    <MDTypography variant="h6" className="mb-0">
                      Upcoming Series
                    </MDTypography>
                    
                  </MDBox>
                </MDBox>
                <MDBox>
                  <table className="table-default table-dashboard-custom">
                    <thead>
                      <th>Series Name</th>
                      <th>Total Brands</th>
                      <th>Total Plans</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>T20 World Cup</td>
                        <td>100</td>
                        <td>100</td>
                      </tr>
                    </tbody>
                  </table>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
            <Card>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
                  <MDBox className="dashboard-table-box">
                    <MDTypography variant="h6" className="mb-0">
                      Latest Plans Created
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox>
                  <table className="table-default table-dashboard-custom">
                    <thead>
                      <th>File Name</th>
                      <th>Series Name</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>File name 1</td>
                        <td>T20 World Cup</td>
                      </tr>
                    </tbody>
                  </table>
                </MDBox>
              </Card>
                  
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
