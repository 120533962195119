import React, {useEffect, useState} from "react"
import { Link } from "react-router-dom";
import {Grid, Box, Card, LinearProgress, Tooltip, Checkbox} from "@mui/material";
import {Button} from 'react-bootstrap';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import 'pages/common.css'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";

import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';


// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import DataTable from "common/Tables/DataTable";
import API from "apiConfig";
import axios from "axios";

function ViewBrand() {
  const resultPerPage = 20;
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const [brandDetails, setBrandDetails] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getBrandDetails();
   },[]);
 
 //function to get brand details
 const getBrandDetails = async (pageNo) => {
  setIsLoading(true);
   let pageCount = (pageNo !== undefined && pageNo !== null) ? Number((pageNo - 1)*resultPerPage) : 0 ;
   let activePageIndex = (pageNo !== undefined && pageNo !== null) ? pageNo : 1 ;
     try {
         const response = await API.get(`brand/view_brands/${pageCount}`);
         if (response.data.status) {
              if(response.data.data)
              {
                let total_records = response.data.data.total_records;
                setTotalRecords(Math.ceil(JSON.parse(total_records)/resultPerPage));
                setCurrentPage(activePageIndex);
                setBrandDetails(response.data.data.data);
                setIsLoading(false);
              }else{
                setBrandDetails([])
                setTotalRecords(0)
                setCurrentPage(1);
                setIsLoading(false);
                alert(response.data.message)
              }
         }
         else {
             setIsLoading(false);
             setCurrentPage(1);
             alert("Something went wrong! Please try again");
             window.location.reload();
         }
     }
     catch (error) {
         alert(error && error.response && error.response.data && error.response.data.message)
     }
 }

 const handleStatusUpdate = async (id, value) => {
  let ID = parseInt(id);
  let VALUE = parseInt(value);
  const response = await API.get(`brand/update_status/${ID}/${VALUE}`);
  if (response.data.status) {
      alert(`${response.data.message}`);
      getBrandDetails();
     // window.location.reload();
  }
  else if (!response.data.status) {
      alert(`${response.data.message.replace(/<\/?[^>]+(>|$)/g, "")}`);
  }
  else {
      console.log("Error")
  }
}
  return ( 
    
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Box px={3} pt={3} sx={{ width: '100%' }}>
            <Box className="text-right" px={2} pt={2}>
              <Link to="/create-brand" ><Button size="small">Create Brand</Button></Link>
            </Box>
            <Box pt={6} pb={3}>
                
              <Grid container spacing={6}>
                <Grid item xs={12}>
                
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Brand List
                      </MDTypography>
                    </MDBox>
                    {!isLoading ?
                    <Box>
                    <DataTable
                     entriesPerPage={false}
                     pagination={false}
                     showTotalEntries={false}
                          table={{
                              columns: [
                              { Header: "Brand Name", accessor: "name"},
                              { Header: "Brand Email", accessor: "email"},
                              { Header: "ITW Sales POC Name", accessor: "itwSalesPocName"},
                              { Header: "ITW IO Digitial POC Name", accessor: "ioDigitalName"},
                              { Header: "Region", accessor: "region"},
                              { Header: "Status", accessor: "status" },
                              // { Header: "action", accessor: "action", align: "center" },
                              ],
                              rows: 
                              brandDetails.map(item => (
                                {
                                  name: item.brand_name,
                                  email: item.brand_contact_email ? item.brand_contact_email : "-",
                                  itwSalesPocName: item.itw_contact_name ? item.itw_contact_name : "-",
                                  ioDigitalName: item.digital_io_contact_name ? item.digital_io_contact_name : "-",
                                  region: item.region,
                                  status: item.status === "1" ? 
                                    <div>
                                      <span className="status bg-approved">Active</span>
                                      <Checkbox
                                        checked={true}
                                        onChange={(e) => handleStatusUpdate(item.id, 0)}
                                      />
                                    </div>
                                    :
                                    <div>
                                      <span className="status bg-qc-rejected">Inactive</span><Checkbox
                                        checked={false}
                                        onChange={(e) => handleStatusUpdate(item.id, 1)}
                                      />
                                    </div> ,
                                  // action:(
                                  //   <Link to="#" >
                                  //     <Tooltip title="Edit" arrow>
                                  //         <Button className="btn btn-primary btn-sm">
                                  //             <ModeOutlinedIcon />
                                  //         </Button>
                                  //     </Tooltip>
                                  // </Link>
                                  // ),
                                } 
                              ))
                          }}
                          />
                      
                    {totalRecords > 1 && (
                      <Box className="py-3 mb-3" >
                        <Stack spacing={2} alignItems="end">
                          <Pagination page={currentPage} count={totalRecords} onChange={(e, index) => getBrandDetails(index)} color="primary" />
                        </Stack>
                      </Box>
                    )}
                  </Box>
                  :
                  <Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                    <LinearProgress color="success" />
                  </Stack>
                  }
                </Card>
                
              </Grid>
              
              </Grid>
            </Box>
          </Box>
        </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewBrand;
