/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import SignUp from "pages/authentication/SignUp";
import ResetPassword from "pages/authentication/ResetPassword";
import { Routes, Route, Navigate } from "react-router-dom";
import { Context as AuthContext } from "./context/AuthContext";
import App from "App";

export default function Main() {
  const { state } = useContext(AuthContext);
  return state.isSignedIn === true ? <AuthRoutes /> : <LoginRoutes />;
}

const LoginRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SignUp />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="*" element={<Navigate to="/sign-up" />} />
    </Routes>
  );
};

const AuthRoutes = () => {
  return <App />;
};
