import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { Grid, TextField, Card, Tabs, Tab, Typography, Box, Modal, Icon, MenuItem, FormControl, Select, FormHelperText, LinearProgress , Tooltip} from "@mui/material";
import { Button, Row, Col, } from 'react-bootstrap';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";

import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";

import DataTable from "common/Tables/DataTable";

import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import { lineItemNameValidator } from "Validation";
import { creativeStatusLabel } from "Helper";
import InfoIcon from '@mui/icons-material/Info';
import API from "apiConfig";
import { useLocation } from 'react-router-dom'
import { DateFormat } from "Helper";

function LineItemList() {
    const location = useLocation();
    const resultPerPage = 20;
    const { columns, rows } = authorsTableData();
    const { columns: pColumns, rows: pRows } = projectsTableData();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [value, setValue] = React.useState(0);
    const [liveDate, setLiveDate] = useState(new Date());
    const [lineItemList, setLineItemList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [lineItemDetails, setLineItemDetails] = useState({
        lineItem: null, lineItemName: null,
        liveDate: new Date(), adDuration: null
    });
    const [errorDetails, setErrorDetails] = useState({
        lineItem: null, lineItemName: null,
        liveDate: null, adDuration: null
    });

    useEffect(() => {
        getLineItemList();
       },[]);

        //function to get lineItem list
     const getLineItemList = async (pageNo) => {
        setIsLoading(true);
        let pageCount = (pageNo !== undefined && pageNo !== null) ? Number((pageNo - 1)*resultPerPage) : 0 ;
        let activePageIndex = (pageNo !== undefined && pageNo !== null) ? pageNo : 1 ;
        try {
            const response = await API.get(`series/view_line_items/${pageCount}`);
            if (response.data.status) {
                console.log("view_line_items",response.data.data.data);
               // console.log("total_records",response.data.data.total_records);
                let total_records = response.data.data.total_records;
                setTotalRecords(Math.ceil(JSON.parse(total_records)/resultPerPage));
                setCurrentPage(activePageIndex);
                setLineItemList(response.data.data.data);
                setIsLoading(false);
            }
            else {
                console.log("Error")
                setIsLoading(false);
                setCurrentPage(1);
                alert("Something went wrong! Please try again");
                window.location.reload();
            }
        }
        catch (error) {
            alert(error && error.response && error.response.data && error.response.data.message)
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleInputChange = (e) => {
        setLineItemDetails({ ...lineItemDetails, [e.target.name]: e.target.value });
        setErrorDetails({ ...errorDetails, [e.target.name]: null });
    }

    const checkValidation = () => {
        let lineItemError = lineItemDetails.lineItem === null ? "Please select line item" : null;
        let lineItemNameError = lineItemNameValidator(lineItemDetails.lineItemName);
        let liveDateError = lineItemDetails.liveDate === null ? "Please select live date" : null;
        let adDurationError = (!lineItemDetails.adDuration || lineItemDetails.adDuration.length <= 0) ? "Please enter ad duration" : null;

        if (lineItemError || lineItemNameError || liveDateError || adDurationError) {
            setErrorDetails({
                ...errorDetails,
                lineItem: lineItemError,
                lineItemName: lineItemNameError,
                liveDate: liveDateError,
                adDuration: adDurationError
            })
        }
        else {
            createLineItem();
        }
    }
    const dateFormat1=(date)=>{
        let formatedDate = new Date(date).getFullYear()  + "-" + new Date(date).getMonth()  + "-" + new Date(date).getDate();
        return formatedDate;
    }

    const createLineItem = async () => {
        let { item } = location.state;
        let dataToSend = {
            "brand_id": item.brand_id,
            "series_id": item.series_id,
            "line_item_id": lineItemDetails.lineItem,
            "line_item_name": lineItemDetails.lineItemName,
            "live_date": dateFormat1(lineItemDetails.liveDate),
            "ad_duration": lineItemDetails.adDuration
        }
        console.log("dataToSend", dataToSend);
        const response = await API.post('series/create_line_item_list', dataToSend);
        if (response.data.status) {
            alert(`${response.data.message}`);
            window.location.reload();
        }
        else if (!response.data.status) {
            alert(`${response.data.message.replace(/<\/?[^>]+(>|$)/g, "")}`);
        }
        else {
            console.log("Error")
        }
    }

    const handlePickNumeric = (e) => {
        setErrorDetails({ ...errorDetails, [e.target.name]: null });
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setLineItemDetails({ ...lineItemDetails, [e.target.name]: onlyNums });
    }

    const dateFormat=(date)=>{
        let formatedDate = new Date(date).getDate()  + "-" + new Date(date).getMonth()  + "-" + new Date(date).getFullYear();
        return formatedDate;
    }


    return (


        <DashboardLayout>
            <DashboardNavbar />
            <Card pb="3">
                <MDBox className="text-right" px={3} pt={3}>
                    <Button size="small" onClick={handleOpen}>Create Line Item</Button>
                </MDBox>

                <Box px={3} pt={3} sx={{ width: '100%' }}>

                    <MDBox pt={3} pb={3}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>

                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                    >
                                        <MDTypography variant="h6" color="white">
                                            Line Item List
                                        </MDTypography>
                                    </MDBox>
                                    {!isLoading ?
                                    <MDBox>
                                        <DataTable
                                        entriesPerPage={false}
                                        pagination={false}
                                        showTotalEntries={false}
                                            table={{
                                                columns: [
                                                    { Header: "Creative Status", accessor: "creative_status" },
                                                    { Header: "Line Item Name", accessor: "live_item_name" },
                                                    { Header: "Live Date ", accessor: "live_date" },
                                                    { Header: "Ad Duration", accessor: "ad_duration" },
                                                    { Header: "Created Date", accessor: "created_date" },
                                                    { Header: "Action", accessor: "action" },
                                                ],
                                                rows: 
                                                 lineItemList.map(item => (
                                                    {
                                                        creative_status: (<span className="status bg-pending">{creativeStatusLabel(item.id)} </span>),
                                                        live_item_name: item.line_item_name,
                                                        live_date:  DateFormat(item.live_date),
                                                        ad_duration: item.ad_duration,
                                                        created_date: DateFormat(item.created_datetime),
                                                        action: (
                                                            <Link to="#" >
                                                                <Tooltip title="Edit" arrow>
                                                                    <Button className="btn btn-primary btn-sm">
                                                                        <ModeOutlinedIcon />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Link>
                                                        )
                                                    }
                                                ))
                                                // [
                                                //     {
                                                //         creative_status: (<span className="status bg-pending">PENDING </span>),
                                                //         live_item_name: "Preroll Live_10 Sec Ad Video_ROS",
                                                //         live_date: "3/10/2022",
                                                //         due_date: "2/7/2022",
                                                //         reason: "Static Image Issue",
                                                //         action: (
                                                //             <Link to="#" >
                                                //                 <MDTypography className="text-primary" component="a" href="#" color="text">
                                                //                     <Icon>edit </Icon>
                                                //                 </MDTypography>
                                                //             </Link>
                                                //             ), 
                                                //     },
                                                // ]
                                                }}
                                            />
                                             {totalRecords > 1 && (
                                            <Box className="py-3 mb-3" >
                                                <Stack spacing={2} alignItems="end">
                                                    <Pagination page={currentPage} count={totalRecords} onChange={(e, index)=> getLineItemList(index)} color="primary" />
                                                </Stack>
                                            </Box>
                                             )}
                                        </MDBox>
                                    :
                                    <Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                        <LinearProgress color="success" />
                                    </Stack>
                                    }
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </Box>
            </Card>



            <Modal
                className="modal-width"
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-body">
                    <Box psx={{ width: '100%' }}>
                        <Typography mb={2} variant="h5" component="div" className="primary-text">
                            CREATE LINE ITEM
                        </Typography>

                        <Box >
                            <Box className="mt-3">

                                <Box component="form" mt={1} noValidate autoComplete="off" >
                                    <Row>
                                        <Col item xs={12} md={12} >
                                            <label className="text-xs">Select Line Item </label>
                                            <FormControl fullWidth mt={1}>
                                                {/* <InputLabel className="overflow-visible" id="demo-simple-select-label">Choose Line Item</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Select Region"
                                        > */}
                                                <TextField
                                                    type="text"
                                                    className="form-control mt-1"
                                                    id="demo-simple-select"
                                                    label="Choose Line Item"
                                                    variant="outlined"
                                                    name="lineItem"
                                                    select
                                                    onChange={handleInputChange}
                                                    value={lineItemDetails.lineItem}
                                                    error={errorDetails.lineItem}
                                                    helperText={errorDetails.lineItem}
                                                >
                                                    <MenuItem value={1}>Astons</MenuItem>
                                                    <MenuItem value={2}>Branded Cards</MenuItem>
                                                    <MenuItem value={3}>Midroll CTV</MenuItem>
                                                    <MenuItem value={4}>Midroll with Carousel</MenuItem>
                                                    <MenuItem value={5}>Midroll without Carousel</MenuItem>
                                                    <MenuItem value={6}>Preroll - Description Type</MenuItem>
                                                    <MenuItem value={7}>Preroll - Install Type</MenuItem>
                                                    {/* </Select> */}
                                                </TextField>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </Box>
                            </Box>
                            <Box component="form" mt={2} noValidate autoComplete="off" >
                                <Row>
                                    <Col item xs={12} md={12} >
                                        <label className="text-xs">Line Item Name</label>
                                        <TextField
                                            type="text"
                                            className="form-control mt-1"
                                            id="outlined-basic"
                                            label="Line Item Name"
                                            variant="outlined"
                                            name="lineItemName"
                                            onChange={handleInputChange}
                                            value={lineItemDetails.lineItemName}
                                            error={errorDetails.lineItemName}
                                            helperText={errorDetails.lineItemName}
                                            autoComplete="off"
                                        />
                                    </Col>
                                    <Col item xs={12} md={12} mt={3}>
                                        <label className="text-xs">Live Date</label>
                                        <DatePicker
                                            className="form-control mt-1"
                                            onChange={(date) => { setLineItemDetails({ ...lineItemDetails, liveDate: date });; setErrorDetails({ ...errorDetails, liveDate: null }) }}
                                            value={lineItemDetails.liveDate}
                                            minDate={new Date()}
                                            format="dd-MMM yyyy"
                                        />
                                        <FormHelperText error={errorDetails.liveDate}>{errorDetails.liveDate}</FormHelperText>
                                    </Col>
                                    <Col item xs={12} md={12} mt={3}>
                                        <label className="text-xs">Ad Duration</label>
                                        <TextField
                                            type="text"
                                            className="form-control mt-1"
                                            id="outlined-basic"
                                            label="Ad Duration"
                                            variant="outlined"
                                            name="adDuration"
                                            onChange={handlePickNumeric}
                                            value={lineItemDetails.adDuration}
                                            error={errorDetails.adDuration}
                                            helperText={errorDetails.adDuration}
                                            InputProps={{ endAdornment: <InfoIcon /> }}
                                            autoComplete="off"
                                        />
                                    </Col>

                                </Row>
                            </Box>
                            <Box mt={3}>
                                <Button size="small" onClick={() => checkValidation()}>Create Line Item</Button>
                            </Box>
                        </Box>

                    </Box>

                </Box>
            </Modal>


            <Footer />
        </DashboardLayout>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




export default LineItemList;
