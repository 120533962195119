import axios from 'axios';
import { INVALID_TOKEN, TOKEN_EXPIRED, APPLICATION_JSON } from './constants';

const isProd = window.location.hostname === 'itwgo.com'
const isTest = ['test.com'].includes(window.location.hostname)
let hostApi='';

if(isProd)
    hostApi = process.env.REACT_APP_PROD_URL
else if (isTest)
    hostApi = process.env.REACT_APP_TEST_URL
else
    hostApi = process.env.REACT_APP_TEST_URL

const instance = axios.create({
    baseURL: hostApi,
    timeout: 1000000
})

const refreshToken = async (token) => {
    let newRes
    try {
      newRes = await instance.post('/login/refresh_token', {refresh_token: token.refresh_token, user_id: token.user_id  }) // token.refreshToken
      let token_obj = {
        access_token: newRes.data.token.access_token,
        refresh_token: newRes.data.token.refresh_token,
        user_id: newRes.data.token.user_id
    }
    localStorage.setItem('token', JSON.stringify(token_obj));
    newRes = token_obj;
    } catch (error) {
      newRes = null
    }
    return newRes
}

const sessionExpired = () => {
    localStorage.clear();
    return (window.location.href = '/login');
}

const get = async (url, param, time)=>{
    const axiosConfig = {
        headers : {
            'Accept': APPLICATION_JSON,
            'Content-Type': APPLICATION_JSON
        }
    }
const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : {};
axiosConfig.headers.Authorization = token.access_token;
if(time) axiosConfig.timeout = time.timeout
if(param) axiosConfig.params = param.params
let res = await instance.get(url, axiosConfig );
let respo;
if(res.data.msg === INVALID_TOKEN){
    return sessionExpired();
}
if(res.data.msg === TOKEN_EXPIRED){

    let newRes = await refreshToken(token);
    if(newRes){
    axiosConfig.headers.Authorization = newRes.access_token;
    let response = await instance.get(url, axiosConfig)

    if(response.data.msg === INVALID_TOKEN){
        return sessionExpired();
    }
    respo = response; 
    }
    else {
        respo = null
        return sessionExpired();
      }
}
else{
    respo = res;
}
return respo;
}


//// It is an axios delete wraper////
const remove = async (url)=>{
const axiosConfig = {
    headers : {
        'Accept': APPLICATION_JSON,
        'Content-Type': APPLICATION_JSON
    }
}
const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : {};
axiosConfig.headers.Authorization = token.access_token;
let res = await instance.delete(url, axiosConfig );
let respo;
if(res.data.msg === INVALID_TOKEN){
    return sessionExpired();
}
if(res.data.msg === TOKEN_EXPIRED){

    let newRes = await refreshToken(token);
    axiosConfig.headers.Authorization =  newRes.access_token;
    let response = await instance.delete(url, axiosConfig)

    if(response.data.msg === INVALID_TOKEN){
        return sessionExpired();
    }

    respo = response; 
}
else{
    respo = res;
}
return respo;
}

const post = async (url, data, withCred)=>{
const axiosConfig = {
    headers : {
        'Accept': APPLICATION_JSON,
        'Content-Type': APPLICATION_JSON
    }
}
const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : {};
axiosConfig.headers.Authorization = token.access_token;
let res;
let respo;
if(withCred && !withCred.withCredentials){
    if(withCred.headers){
        if(withCred.headers["Content-Type"] === undefined){
            axiosConfig.headers["Content-type"] = undefined
        }
        else{
            axiosConfig.headers["Content-type"] = withCred.headers["Content-Type"]
        }
        res = await instance.post(url, data, axiosConfig);
    }
    else{
     res = await instance.post(url, data);
    }
    respo = res;
}
else{
    res = await instance.post(url, data, axiosConfig);
    if(res.data.msg === INVALID_TOKEN){
        return sessionExpired();
    }
    if(res.data.msg === TOKEN_EXPIRED){
        let newRes = await refreshToken(token);

        axiosConfig.headers.Authorization = newRes.access_token;
        
        res = await instance.post(url, data, axiosConfig);
        if(res.data.msg === INVALID_TOKEN){
            return sessionExpired();
        }
    }
    respo = res;
}
return respo;
}

const put = async (url, data)=>{
const axiosConfig = {
    headers : {
        'Accept': APPLICATION_JSON,
        'Content-Type': APPLICATION_JSON
    }
}
const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : {};
axiosConfig.headers.Authorization =  token.access_token;
let res = await instance.put(url, data, axiosConfig);
if(res.data.msg === INVALID_TOKEN){
    return sessionExpired();
}
if(res.data.msg === TOKEN_EXPIRED){
    let newRes = await refreshToken(token);

    axiosConfig.headers.Authorization =  newRes.access_token;
    
    res = await instance.put(url, data, axiosConfig);
    if(res.data.msg === INVALID_TOKEN){
        return sessionExpired();
    }
}

return res;
}

const API = {get, post, remove, put};

export default API;