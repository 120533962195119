// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import { useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import TextField from "@mui/material/TextField";

// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { phoneValidator, emailValidator } from "Validation";
import API from "apiConfig";

function ResetPassword() {
  const [userDetails, setUserDetails] = useState({ phoneNumber: null, email: null });
  const [errorDetails, setErrorDetails] = useState({ phoneNumber: null, email: null });

  const handleInputChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    setErrorDetails({ ...errorDetails, [e.target.name]: null });
  }

  const onResetPassword = async() => {
    let dataToSend = {
      "email": userDetails.email,
      "phone": userDetails.phoneNumber
    }
    console.log("dataToSend", dataToSend);
    const response = await API.post('login/forgot_password', dataToSend);
    if (response.data.status) {
         alert(`${response.data.data}`);
         return (window.location.href = '/login');
    }
    else {
        console.log("Error")
    }
  }

  const checkValidation = () => {
    let phoneNumber = phoneValidator(userDetails.phoneNumber);
    let email = emailValidator(userDetails.email);
    console.log("EmailError", email)
    console.log("phoneNumberError", phoneNumber)
    if (email || phoneNumber) {
      setErrorDetails({
        ...errorDetails,
        phoneNumber: phoneNumber,
        email: email
      })
      return;
    }
    else {
      onResetPassword();
    }
  }

  const handleChange = (e) => {
    setErrorDetails({ ...errorDetails, phoneNumber: null });
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 10) {
      setUserDetails({ ...userDetails, phoneNumber: onlyNums })
    } else if (onlyNums.length === 10) {
      const number = onlyNums;
      setUserDetails({ ...userDetails, phoneNumber: number })
    }
  }

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              {/* <MDInput type="text" label="Phone Number" variant="standard" fullWidth /> */}
              <TextField
                variant="standard"
                className="inputboxdetails"
                type="text"
                label="Phone Number"
                name="phone"
                fullWidth
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                onChange={(e) => handleChange(e)}
                value={userDetails.phoneNumber}
                error={errorDetails.phoneNumber}
                helperText={errorDetails.phoneNumber}
              />
            </MDBox>
            <MDBox mb={4}>
              {/* <MDInput type="email" label="Email" variant="standard" fullWidth /> */}
              <TextField
                variant="standard"
                className="inputboxdetails"
                type="email"
                label="Email"
                name="email"
                fullWidth
                onChange={handleInputChange}
                value={userDetails.email}
                error={errorDetails.email}
                helperText={errorDetails.email}
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={() => checkValidation()}>
                reset
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default ResetPassword;