import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { Grid, Box, Card, LinearProgress, Tooltip, Checkbox, TextField, CardContent, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Button, Row, Col, Table } from 'react-bootstrap';
// Material Dashboard 2 React components

import 'pages/common.css'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import DownloadIcon from '@mui/icons-material/Download';
import API from "apiConfig";
import axios from "axios";
import { useNavigate } from 'react-router-dom'
import MediaPlanExcelSheet from "pages/media-plan-sheet/MediaPlanExcelSheet";

function Bag() {
  const navigate = useNavigate();
  const resultPerPage = 20;
  const [mediaPlanDetails, setMediaPlanDetails] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showExcel, setShowExcel] = useState(false);
  const [isSearchStart, setIsSearchStart] = useState(false);
  const [bagDetails, setBagDetails] = useState({
    fileName: '', brandName: '', tournament: '',
    digitalPoc: '', salesPoc: '', region: 'All Region', createdBy: ''
  })
  const [allForms, setAllForms] = useState([]);
  const [brandDetails, setBrandDetails] = useState({});
  useEffect(() => {
    getMediaPlanList();
  }, []);

  //function to get brand details
  //  const getBrandDetails = async (pageNo) => {
  //   setIsLoading(true);
  //    let pageCount = (pageNo !== undefined && pageNo !== null) ? Number((pageNo - 1)*resultPerPage) : 0 ;
  //    let activePageIndex = (pageNo !== undefined && pageNo !== null) ? pageNo : 1 ;
  //      try {
  //          const response = await API.get(`brand/view_brands/${pageCount}`);
  //          if (response.data.status) {
  //              console.log("Response",response.data.data.data);
  //              let total_records = response.data.data.total_records;
  //              setTotalRecords(Math.ceil(JSON.parse(total_records)/resultPerPage));
  //              setCurrentPage(activePageIndex);
  //             setBrandDetails(response.data.data.data);
  //             setIsLoading(false);
  //          }
  //          else {
  //              console.log("Error");
  //              setIsLoading(false);
  //              setCurrentPage(1);
  //             //  alert("Something went wrong! Please try again");
  //              window.location.reload();
  //          }
  //      }
  //      catch (error) {
  //         //  alert(error && error.response && error.response.data && error.response.data.message)
  //      }
  //  }

  const handleBagDetails = (e) => {
    setBagDetails({ ...bagDetails, [e.target.name]: e.target.value });
  }

  //function to get Media Plan list
  const getMediaPlanList = async (pageNo) => {
    setIsLoading(true);
    let pageCount = (pageNo !== undefined && pageNo !== null) ? Number((pageNo - 1) * resultPerPage) : 0;
    let activePageIndex = (pageNo !== undefined && pageNo !== null) ? pageNo : 1;
    let file_name = bagDetails.fileName;
    let brand_name = bagDetails.brandName;
    let tournament = bagDetails.tournament;
    let digital_poc = bagDetails.digitalPoc;
    let sales_poc = bagDetails.salesPoc;
    let region = bagDetails.region === 'All Region' ? '' : bagDetails.region;
    let created_by = bagDetails.createdBy;


    try {
      const response = await API.get(`media_planning/getMediaPlanList/${pageCount}?file_name=${file_name}&brand_name=${brand_name}&tournament=${tournament}&digital_360_poc=${digital_poc}&brand_sales=${sales_poc}&region=${region}&created_by=${created_by}`);
      if (response.data.status) {
        setMediaPlanDetails([]);
        let total_records = response.data?.data?.total_records;
        if (total_records) {
          setTotalRecords(Math.ceil(JSON.parse(total_records) / resultPerPage));
          setCurrentPage(activePageIndex);
          setMediaPlanDetails(response.data.data.data);
          setIsLoading(false);
          setIsSearchStart(true);
        }
        else {
          setIsLoading(false);
          setCurrentPage(1);
          setIsSearchStart(true);
        }
      }
      else {
        setIsLoading(false);
        setCurrentPage(1);
        setIsSearchStart(true);
        setMediaPlanDetails([]);
      }
    }
    catch (error) {
      setIsLoading(false);
      setCurrentPage(1);
      setIsSearchStart(true);
      alert("Something went wrong! Please try again");
    }
  }

  //function to get Raw Grid Details
  const getMediaPlanDetails = async (origin, key) => {
    setIsLoading(true);

    try {
      const response = await API.get(`media_planning/getMediaPlanDetails?origin=${origin}`);
      if (response.data.status) {
        setIsLoading(false);
        // console.log("Response",response.data.data.data)
        //  setAllForms(response.data.data.data.Forms);
        let dataForms = response.data.data.data.Forms.map((x, i) => { return ({ forms: x, id: i, outlay: Number(x[0].wholeOutlay) }) })
        // console.log("dataForms",dataForms);
        if(key === "get"){
        setAllForms(dataForms);
        setBrandDetails(response.data.data.data.Details)
        setShowExcel(true);
        setTimeout(() => setShowExcel(false), 2000);
        }
        else{
        //   navigate({pathname: '/update-media-plan-sheet',  state: {
        //     forms: dataForms,
        //     brandDetails: response.data.data.data.Details
        //   },
        // })
        navigate('/update-media-plan-sheet', { state: {
          origin: origin
        },})
        }
      }
      else {
        console.log("Error");
        setAllForms([]);
        setBrandDetails({});
        setShowExcel(false);
        setIsLoading(false);
        alert("Something went wrong! Please try again");
      }
    }
    catch (error) {
      setAllForms([]);
      setBrandDetails({});
      setShowExcel(false);
      setIsLoading(false);
      alert("Something went wrong! Please try again");
    }
  }

  //console.log("allForms", allForms, "brandDetails",brandDetails)

  return (

    <DashboardLayout>
      <DashboardNavbar />
      {showExcel && (
        <MediaPlanExcelSheet allForms={allForms} brandDetails={brandDetails} />
      )}
      <Card className="bag-container">
        <Box px={3} pb={3} sx={{ width: '100%' }}>
          <CardContent className="px-0">
            <Typography variant="h4" component="div" className="primary-text">
              Bag
            </Typography>

          </CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>

              <Card>
                <Box className="table-container">
                  <table className="table-default">
                    <thead>
                      <tr>
                        <th>
                          <div className="td-content">
                            <TextField
                              id="standard-basic"
                              placeholder="Search By File name"
                              variant="standard"
                              name="fileName"
                              onChange={handleBagDetails}
                              value={bagDetails.fileName}
                              autoComplete="off"
                            />
                          </div>
                        </th>
                        <th>
                          <div className="td-content">
                            <TextField
                              id="standard-basic"
                              placeholder="Search By Brand Name "
                              variant="standard"
                              name="brandName"
                              onChange={handleBagDetails}
                              value={bagDetails.brandName}
                              autoComplete="off"
                            />
                          </div>
                        </th>
                        <th><div className="td-content">
                          <TextField
                            id="standard-basic"
                            placeholder="Filter By Tournament"
                            variant="standard"
                            name="tournament"
                            onChange={handleBagDetails}
                            value={bagDetails.tournament}
                            autoComplete="off"
                          />
                        </div></th>
                        <th><div className="td-content">
                          <TextField
                            id="standard-basic"
                            placeholder="Filter By Digi-360 POC"
                            variant="standard"
                            name="digitalPoc"
                            onChange={handleBagDetails}
                            value={bagDetails.digitalPoc}
                            autoComplete="off"
                          />
                        </div></th>
                        <th><div className="td-content">
                          <TextField
                            id="standard-basic"
                            placeholder="Filter By ITW Sales POC"
                            variant="standard"
                            name="salesPoc"
                            onChange={handleBagDetails}
                            value={bagDetails.salesPoc}
                            autoComplete="off"
                          />
                        </div></th>
                        <th>
                          <div className="td-content">
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Filter by Region"
                                name="region"
                                onChange={handleBagDetails}
                                value={bagDetails.region}
                              >
                                <MenuItem value={'All Region'} selected>All Region</MenuItem>
                                <MenuItem value={'Mumbai'}>Mumbai</MenuItem>
                                <MenuItem value={'Bangalore'}>Bangalore</MenuItem>
                                <MenuItem value={'Delhi'}>Delhi</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </th>
                        <th><div className="td-content">
                          <TextField
                            id="standard-basic"
                            placeholder="Filter By Created By"
                            variant="standard"
                            name="createdBy"
                            onChange={handleBagDetails}
                            value={bagDetails.createdBy}
                            autoComplete="off"
                          />
                        </div></th>
                        <th>
                          <div>
                            <Button
                              size="sm"
                              variant="contained"
                              color="primary"
                              className="btn-primary"
                              onClick={() => getMediaPlanList()}
                            >
                              Search
                            </Button>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {mediaPlanDetails?.map(item =>
                        <tr>
                          <td><a
                          onClick={() => getMediaPlanDetails(item.origin, "update")}
                          className="td-content" style={{cursor: "pointer"}}>{item.file_name}</a></td>
                          <td><div className="td-content">{item.brand_name}</div></td>
                          <td><div className="td-content">{item.series_name}</div></td>
                          <td><div className="td-content">{item.digital_360_poc}</div></td>
                          <td><div className="td-content">{item.brand_sales}</div></td>
                          <td><div className="td-content">{item.region}</div></td>
                          <td><div className="td-content">{item.created_by}</div></td>
                          <td><div className="td-content">
                            <Button size="sm"
                              variant="contained"
                              color="success"
                              className="btn-success"
                              onClick={() => getMediaPlanDetails(item.origin, "get")}
                            ><DownloadIcon /> Download</Button></div></td>
                        </tr>
                      )}
                      {(isSearchStart && mediaPlanDetails.length === 0) &&
                        <tr><td colspan="8"><div className="td-content">No data found.</div></td></tr>
                      }
                    </tbody>
                  </table>
                </Box>

                {!isLoading ?
                  <Box>

                    {totalRecords > 1 && (
                      <Box className="py-3 mb-3" >
                        <Stack spacing={2} alignItems="end">
                          <Pagination page={currentPage} count={totalRecords} onChange={(e, index) => getMediaPlanList(index)} color="primary" />
                        </Stack>
                      </Box>
                    )}
                  </Box>
                  :
                  <Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                    <LinearProgress color="success" />
                  </Stack>
                }
              </Card>

            </Grid>

            {/* <Grid md={12} item >
                <Stack spacing={2} className="text-center">
                    <Pagination count={5}  color="primary" />
                </Stack>
              </Grid> */}
          </Grid>
        </Box>
      </Card>

      <Footer />
    </DashboardLayout>
  );
}

export default Bag;
