/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import TextField from "@mui/material/TextField";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useContext, useState } from "react";
import { Context as AuthContext } from '../../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { emailValidator, passwordValidator } from "Validation";
import { IconButton, InputAdornment } from "@mui/material";



function SignUp() {
  const [userDetails, setUserDetails] = useState({ username: null, password: null });
  const [errorDetails, setErrorDetails] = useState({ username: null, password: null });
  const [showPassword, setShowPassword] = useState(false);
  const { signin } = useContext(AuthContext);
  let navigate = useNavigate();
  let location = useLocation();

  const handleInputChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    setErrorDetails({ ...errorDetails, [e.target.name]: null });
  }

  const checkValidation = () => {
    let emails = emailValidator(userDetails.username);
    let password = passwordValidator(userDetails.password);
    console.log("EmailError", emails)
    console.log("passwordError", password)
    if (emails || password) {
      setErrorDetails({
        ...errorDetails,
        username: emails,
        password: password
      })
      return;
    }
    else {
      submit();
    }
  }

  const submit = async () => {
    if (!userDetails.username || !userDetails.password) {
      return alert("Please fill all the fields");
    }
    else {
      const signInResp = await signin(userDetails)
      let dataToSend = {
        "username": userDetails.username,
        "password": userDetails.password
      }
      console.log("dataToSend", dataToSend)
      if (signInResp.data.status) {
        navigate('/media-plan-sheet');
      }
      else {
        return alert("Error while login");
      }
    }
  }

  return (

    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Login to your account
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              {/* <MDInput type="email" label="Email" variant="standard" name="username" fullWidth onChange={handleInputChange} error={errorDetails.username}/> */}
              <TextField
                variant="standard"
                size="small"
                className="inputboxdetails"
                type="email"
                label="Email"
                name="username"
                fullWidth
                onChange={handleInputChange}
                value={userDetails.username}
                error={errorDetails.username}
                helperText={errorDetails.username}
              />
            </MDBox>
            <MDBox mb={2}>
              {/* <MDInput type="password" label="Password" variant="standard" name="password" fullWidth onChange={handleInputChange} error={errorDetails.password}/> */}
              <TextField
                variant="standard"
                size="small"
                className="inputboxdetails"
                type={showPassword ? "text" : "password"}
                label="password"
                name="password"
                fullWidth
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                onChange={handleInputChange}
                value={userDetails.password}
                error={errorDetails.password}
                helperText={errorDetails.password}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={() => checkValidation()}>
                Login
              </MDButton>
            </MDBox>
            
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Forgot Password?{" "}
                <MDTypography
                  component={Link}
                  to="/reset-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Click Here
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default SignUp;
