import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Button, Row, Col } from 'react-bootstrap';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import 'pages/common.css';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tooltip from '@mui/material/Tooltip';



// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import DataTable from "common/Tables/DataTable";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DatePicker from 'react-date-picker';
import { Autocomplete, FormHelperText, LinearProgress, MenuItem } from "@mui/material";
import { impressionValidator } from "Validation";
import { outlayValidator } from "Validation";
import API from "apiConfig";
import { useLocation } from 'react-router-dom'
import { DateFormat } from "Helper";

function BrandList(props) {
    const location = useLocation();
    const resultPerPage = 20;
    const { columns, rows } = authorsTableData();
    const { columns: pColumns, rows: pRows } = projectsTableData();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [value, setValue] = React.useState(0);
    const [endDate, setEndDate] = useState(new Date());
    const [liveDate, setLiveDate] = useState(new Date());
    const [filteredBrandList, setFilteredBrandList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [createdBrandList, setCreatedBrandList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [brandDetails, setBrandDetails] = useState({
        brandName: null, liveDate: new Date(),
        endDate: new Date(), impression: null,
        outlay: null, grid: null, brandId: null
    });
    const [errorDetails, setErrorDetails] = useState({
        brandName: null, liveDate: null,
        endDate: null, impression: null,
        outlay: null, grid: null
    });

    useEffect(() => {
        getEventDetails();
       },[]);
     
    //function to get brand details
    const getEventDetails = async (pageNo) => {
        setIsLoading(true);
        let pageCount = (pageNo !== undefined && pageNo !== null) ?  Number((pageNo - 1)*resultPerPage) : 0;
        let activePageIndex = (pageNo !== undefined && pageNo !== null) ? pageNo : 1 ;
        try {
            const response = await API.get(`series/view_brand_series/${pageCount}`);
            if (response.data.status) {
                console.log("view_brand_series", response.data.data.data);
                let total_records = response.data.data.total_records;
                setTotalRecords(Math.ceil(JSON.parse(total_records) / resultPerPage));
                setCurrentPage(activePageIndex);
                setCreatedBrandList(response.data.data.data);
                setIsLoading(false);
            }
            else {
                console.log("Error");
                setIsLoading(false);
                setCurrentPage(1);
                alert("Something went wrong! Please try again");
                window.location.reload();
            }
        }
        catch (error) {
            alert(error && error.response && error.response.data && error.response.data.message)
        }
    }

        //function to get brand list
        const getBrandList = async (data) => {
            try {
                let input = (data !== undefined && data !== null) ? data : ""
                const response = await API.get(`brand/get_brands_list/${input}`);
                if (response.data.status) {
                    // console.log("Response",response.data.data);
                    let data = response.data.data.map(item => item.brand_name);
                    setBrandList([]);
                    setFilteredBrandList([]);
                    setBrandList(data);
                    setFilteredBrandList(response.data.data);
                }
                else {
                    console.log("Error")
                }
            }
            catch (error) {
                alert(error && error.response && error.response.data && error.response.data.message)
            }
        } 

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleInputChange = (e) => {
        setBrandDetails({ ...brandDetails, [e.target.name]: e.target.value });
        setErrorDetails({ ...errorDetails, [e.target.name]: null });
    }

    const checkValidation = () => {
        let brandNameError = brandDetails.brandName === null ? "Please select brand name" : null;
        let liveDateError = brandDetails.liveDate === null ? "Please select live date" : null;
        let endDateError = brandDetails.endDate === null ? "Please select end date" : null;
        let impressionError = impressionValidator(brandDetails.impression);
        let outlayError = outlayValidator(brandDetails.outlay);
        let gridError = brandDetails.grid === null ? "Choose a file" : null;

        if (brandNameError || liveDateError || endDateError || impressionError || outlayError || gridError) {
            setErrorDetails({
                ...errorDetails,
                brandName: brandNameError,
                liveDate: liveDateError,
                endDate: endDateError,
                impression: impressionError,
                outlay: outlayError,
                grid: gridError
            })
        }
        else {
            createBrandList();
        }
    }

    const dateFormat1=(date)=>{
        let formatedDate = new Date(date).getFullYear()  + "-" + new Date(date).getMonth()  + "-" + new Date(date).getDate();
        return formatedDate;
    }

    const createBrandList = async () => {
        let { item } = location.state;
        let brandId = filteredBrandList.filter(item=> item.brand_name === brandDetails.brandName)[0].id;
        let dataToSend = {
            // "brand_name": brandDetails.brandName,
            "brand_id": brandId,
            "series_id": item.id,
            "live_date": dateFormat1(brandDetails.liveDate),
            "end_date": dateFormat1(brandDetails.endDate),
            "impression": brandDetails.impression,
            "outlay": brandDetails.outlay,
           // "grid": brandDetails.grid
        }
        console.log("dataToSend", dataToSend);
        const response = await API.post('series/create_brand_series', dataToSend);
        if (response.data.status) {
            alert(`${response.data.message}`);
            window.location.reload();
        }
        else if (!response.data.status) {
            alert(`${response.data.message.replace(/<\/?[^>]+(>|$)/g, "")}`);
        }
        else {
            console.log("Error")
        }
    }

    const handlePickNumeric = (e) => {
        setErrorDetails({ ...errorDetails, [e.target.name]: null });
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setBrandDetails({ ...brandDetails, [e.target.name]: onlyNums });
    }

    const uploadFileToServer = (event) => {
        console.log("event", event.target.files[0])
        var file = event.target.files[0];
        console.log(file);
        var reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = function () {
            let covertedData = btoa(reader.result);
            console.log("base64", covertedData);
            setErrorDetails({ ...errorDetails, grid: null });
            setBrandDetails({ ...brandDetails, grid: covertedData });
        };
        reader.onerror = function () {
            console.log('there are some problems');
            setBrandDetails({ ...brandDetails, grid: null });
        };
    }

    const dateFormat=(date)=>{
        let formatedDate = new Date(date).getDate()  + "-" + new Date(date).getMonth()  + "-" + new Date(date).getFullYear();
        return formatedDate;
    }


    return (


        <DashboardLayout>
            <DashboardNavbar />
            <Card pb="3">
                <MDBox className="text-right" px={3} pt={3}>
                    <Button size="small" onClick={handleOpen}>Create Brand</Button>
                </MDBox>

                <Box px={3} pt={3} sx={{ width: '100%' }}>

                    <MDBox pt={3} pb={3}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>

                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                    >
                                        <MDTypography variant="h6" color="white">
                                            Brand List
                                        </MDTypography>
                                    </MDBox>
                                    {!isLoading ?
                                    <MDBox>
                                        <DataTable
                                         entriesPerPage={false}
                                         pagination={false}
                                         showTotalEntries={false}
                                            table={{
                                                columns: [
                                                    { Header: "Brand Name", accessor: "name" },
                                                    { Header: "Live Date", accessor: "live_date" },
                                                    { Header: "End Date ", accessor: "end_date" },
                                                    { Header: "Impresstion", accessor: "impresstion" },
                                                    { Header: "Outlay", accessor: "outlay" },
                                                    { Header: "Action", accessor: "action" },
                                                ],
                                                rows:
                                                createdBrandList.map(item => (
                                                    {
                                                        name: item.brand_name,
                                                        live_date: DateFormat(item.live_date),
                                                        end_date: DateFormat(item.end_date),
                                                        impresstion: item.impression,
                                                        outlay: item.outlay,
                                                        action: [(
                                                            <Link to="#" >
                                                                <Tooltip title="Edit" arrow>
                                                                    <Button className="btn btn-primary btn-sm">
                                                                        <ModeOutlinedIcon />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Link>
                                                        ), 
                                                        (<Link to="/line-item-list" state={{ item: item }} className="px-3">
                                                                <Tooltip title="View" arrow>
                                                                    <Button className="btn btn-success btn-sm">
                                                                        <RemoveRedEyeIcon />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Link>
                                                        )]
                                                    
                                                    }
                                                ))
                                                // [
                                                //     {
                                                //         name: "CoinDCX",
                                                //         live_date: "2/9/2022",
                                                //         end_date: "3/10/2022",
                                                //         due_date: "2/7/2022",
                                                //         creative_status: "Pending",
                                                //         coampaign_status: "PENDING",
                                                //         impresstions: "100000",
                                                //         outlay: "INR 20000",
                                                //         grid: (<Button className="text-primary text-sm" variant="outlined">Upload</Button>),
                                                //         hotstar_report: (<Button className="text-primary text-sm" variant="outlined">Upload</Button>),
                                                //         sizmek_report: (<Button className="text-primary text-sm" variant="outlined">Upload</Button>),
                                                //         bls_report: (<Button className="text-primary text-sm" variant="outlined">Upload</Button>),
                                                //         action: (
                                                //             <Link to="/line-item-list" >
                                                //                 <MDTypography className="text-primary" component="a" href="#" color="text">
                                                //                     <Icon>edit </Icon>
                                                //                 </MDTypography>
                                                //             </Link>
                                                //         ),
                                                //     },
                                                // ]
                                            }}
                                        />
                                         {totalRecords > 1 && (
                                         <Box className="py-3 mb-3" >
                                                <Stack spacing={2} alignItems="end">
                                                    <Pagination page={currentPage} count={totalRecords} onChange={(e, index)=> getEventDetails(index)} color="primary" />
                                                </Stack>
                                            </Box>
                                         )}
                                    </MDBox>
                                     :
                                     <Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                        <LinearProgress color="success" />
                                    </Stack>
                                    }
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </Box>
            </Card>



            <Modal
                className="modal-width"
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-body">
                    <Box psx={{ width: '100%' }}>
                        <Typography mb={2} variant="h5" component="div" className="primary-text">
                            Create Brand
                        </Typography>

                        <Box >
                            <Typography variant="h6" component="div">
                                Series Details
                            </Typography>

                            <Box component="form" noValidate autoComplete="off" >
                                <Row>
                                    <Col item xs={12} md={12} >
                                        <label className="text-xs">Brand Name</label>
                                        {/* <TextField
                                            type="text"
                                            className="form-control mt-1"
                                            id="outlined-basic"
                                            label="Brand Name"
                                            variant="outlined"
                                            name="brandName"
                                            select
                                            onChange={handleInputChange}
                                            value={brandDetails.brandName}
                                            error={errorDetails.brandName}
                                            helperText={errorDetails.brandName}
                                        >
                                            <MenuItem key={1} value="test">Test 1</MenuItem>
                                            <MenuItem key={2} value="test2">Test 2</MenuItem>
                                            <MenuItem key={3} value="test3">Test 3</MenuItem>
                                        </TextField> */}
                                        <Autocomplete
                                            disableClearable
                                            value={brandDetails.brandName}
                                            options={brandList}
                                            onChange={(event, newValue) => {
                                              setBrandDetails({...brandDetails, brandName: newValue});
                                              setErrorDetails({...errorDetails, brandName: null});
                                            }}
                                            onFocus={()=>getBrandList()}
                                            size="small"
                                           // sx={{ width: "5rem" }}
                                            renderInput={(params) =>  
                                                            <TextField 
                                                            {...params}  
                                                            className="form-control mt-1"
                                                            id="outlined-basic"
                                                            label="Brand Name"
                                                            variant="outlined"
                                                            name="brandName"
                                                            onChange={(e)=>getBrandList(e.target.value)}
                                                            value={brandDetails.brandName}
                                                            error={errorDetails.brandName}
                                                            helperText={errorDetails.brandName}
                                                            />
                                                        }
                                        />
                                    </Col>
                                    <Col item xs={12} md={6} mt={3}>
                                        <label className="text-xs">Live Date</label>
                                        <DatePicker
                                            className="form-control mt-1"
                                            onChange={(date) => { setBrandDetails({ ...brandDetails, liveDate: date }); setErrorDetails({ ...errorDetails, liveDate: null }) }}
                                            value={brandDetails.liveDate}
                                            minDate={new Date()}
                                            format="dd-MMM yyyy"
                                        />
                                        <FormHelperText error={errorDetails.liveDate}>{errorDetails.liveDate}</FormHelperText>
                                    </Col>
                                    <Col item xs={12} md={6} mt={3}>
                                        <label className="text-xs">End Date</label>
                                        <DatePicker
                                            className="form-control mt-1"
                                            onChange={(date) => { setBrandDetails({ ...brandDetails, endDate: date }); setErrorDetails({ ...errorDetails, endDate: null }) }}
                                            value={brandDetails.endDate}
                                            minDate={new Date(brandDetails.liveDate)}
                                            format="dd-MMM yyyy"
                                        />
                                        <FormHelperText error={errorDetails.endDate}>{errorDetails.endDate}</FormHelperText>
                                    </Col>
                                    <Col item xs={12} md={6} mt={3}>
                                        <label className="text-xs">Impression</label>
                                        <TextField
                                            type="text"
                                            className="form-control mt-1"
                                            id="outlined-basic"
                                            label="Impression"
                                            variant="outlined"
                                            name="impression"
                                            onChange={handlePickNumeric}
                                            value={brandDetails.impression}
                                            error={errorDetails.impression}
                                            helperText={errorDetails.impression}
                                            autoComplete="off"
                                        />
                                    </Col>
                                    <Col item xs={12} md={6} mt={3}>
                                        <label className="text-xs">Outlay</label>
                                        <TextField
                                            type="text"
                                            className="form-control mt-1"
                                            id="outlined-basic"
                                            label="Outlay"
                                            variant="outlined"
                                            name="outlay"
                                            onChange={handlePickNumeric}
                                            value={brandDetails.outlay}
                                            error={errorDetails.outlay}
                                            helperText={errorDetails.outlay}
                                            autoComplete="off"
                                        />
                                    </Col>
                                    <Col item xs={12} md={6} mt={3}>
                                        <label className="text-xs">GRID</label>
                                        {/* <TextField type="file" className="form-control" id="outlined-basic" variant="outlined" onChange={(e)=>console.log("e",e.target.files[0].type)}/> */}
                                        <input
                                            accept=".pdf, .xls, .xlsx, .xlsm, .xlsb, .xltx, .xltm, .xlt, .xml, .xlam, .xla, .xlr, .xlw, "
                                            type="file"
                                            className="form-control"
                                            id="outlined-basic"
                                            variant="outlined"
                                            onChange={uploadFileToServer}
                                        />
                                        <FormHelperText error={errorDetails.grid}>{errorDetails.grid}</FormHelperText>
                                    </Col>
                                </Row>
                            </Box>
                            <Box mt={3}>
                                <Button size="small" onClick={() => checkValidation()}>Create Brand</Button>
                            </Box>
                        </Box>

                    </Box>

                </Box>
            </Modal>


            <Footer />
        </DashboardLayout>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




export default BrandList;
