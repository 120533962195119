import React, {useState} from "react"
import {Container, Card, Box, TextField, Grid, CardContent, Typography, FormHelperText} from "@mui/material";
import 'pages/common.css'
import 'pages/end-user/end-user.css'
import { Link } from "react-router-dom";

// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import ImgIcon from 'assets/images/icons/image-icon.png'
import Banner1 from 'assets/images/match-img.jpg'
import Banner2 from 'assets/images/match.png'

import {Button, Row, Col} from 'react-bootstrap';


function Astons() {
    const [astonImg, setAstonImg] = useState(Banner1)
    const [astonImgName, setAstonImgName] = useState(null);
    const [errorDetails, setErrorDetails] = useState({ 
        astonImg: null
    });
    const [brandDetails, setBrandDetails] = useState({
        astonImg: null
    });

    const astonImage = async (event) => {
        const file = event.target.files[0]
     

        if (file.type != "image/jpeg" && file.type != "image/jpg") {
            setErrorDetails({...errorDetails, astonImg: "Please select a JPG/JPEG file for upload"});
            return ;
        }
        
        let img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.onload = () => {
            if(img.width !== 1920 && img.height !== 162){
                setErrorDetails({...errorDetails, astonImg: `Sorry, this image doesn't look like the size we wanted. It's ${img.width} x ${img.height} but we require 1920 x 162 size image.`});
                return ; 
            }  
            else {
                var reader = new FileReader();
                reader.readAsBinaryString(file);
        
                reader.onload = function () {
                    let covertedData = btoa(reader.result);
                    console.log("base64", covertedData);
                    setErrorDetails({ ...errorDetails, astonImg: null });
                    setBrandDetails({ ...brandDetails, astonImg: covertedData });
                    setAstonImgName(event.target.files[0].name);
                    setAstonImg(URL.createObjectURL(event.target.files[0]));
                };
                reader.onerror = function () {
                    console.log('there are some problems');
                    setBrandDetails({ ...brandDetails, astonImg: null });
                };
            }
        }
    }

    const checkValidation = () => {
        let astonImageError = brandDetails.astonImg === null ? "Please choose Aston image" : null;
       
        if ( astonImageError) {
            setErrorDetails({
                ...errorDetails,
                astonImg: astonImageError
            })
            return;
        }
        else {
          console.log("BrandDetails",brandDetails)
        }
    }

  return ( 
    
    <DashboardLayout>
       <Container> 
            <DashboardNavbar />
            <Box mb={2} />
                <Box mt={3} mb={3}>
                    <Card>
                    <Grid  container>
                        <Grid item xs={12} md={7} xl={7}>
                            <Box>
                                <CardContent>
                                    <Typography pt={2} variant="h4" component="div" className="primary-text">
                                        Aston Image Line Item
                                    </Typography>

                                    <Box className="box-container mt-2">
                                        <Typography  variant="h6" component="div">
                                            Upload Ads and CTA Details
                                        </Typography>

                                        <Box component="form" noValidate  autoComplete="off" >
                                            <Row>
                                                <Col item xs={12} md={12} className="mb-3">
                                                    <label className="text-sm">Line Item Name</label>
                                                    <TextField type="text" className="form-control mt-1" id="outlined-basic" label="Aston" variant="outlined" disabled/>
                                                </Col>
                                                <Col item xs={12} md={12} className="mb-3">
                                                    <label className="text-sm">Aston Image</label>
                                 
                                                    <label htmlFor="aston-image" className="custom-file-upload fas">
                                                        <div className="img-wrap img-upload" >
                                                            <Grid container>
                                                                <Grid xs={12} sm={2} className="ImgWrapper">
                                                                    <img className="ImgIcon" src={ImgIcon}/>
                                                                </Grid>
                                                                <Grid xs={12} sm={10}>
                                                                    <label class="label-1">Drag your Image here, or browse</label>
                                                                    <label className="label-2">Dimension: 1920x162 | File Type: JPG / JPEG | Max File Size: No Limit</label>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <input 
                                                        id="aston-image" 
                                                        type="file" 
                                                        onChange={(e) => {
                                                            astonImage(e)
                                                            }} /> 
                                                    </label>
                                                    {(astonImgName && errorDetails.astonImg === null) ? <p className="text-sm mb-0">Selected File: <b className="text-primary">{astonImgName} </b></p> : null}
                                                    <FormHelperText error={errorDetails.astonImg}>{errorDetails.astonImg}</FormHelperText> 
                                                </Col>
                                            </Row>   

                                            <Row >
                                                <Box item xs={12} md={12} className="pt-2" >
                                                    <Button className="btn btn-primary text-sm mt-3"><AddCircleOutlineIcon /> Add Another/Multiple Videos</Button>
                                                </Box>
                                            </Row>
                                        </Box>
                                    </Box>

                                    <hr />
                                </CardContent>
                                
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5} xl={5}>
                            <Box p={2}>
                                <CardContent>
                                    <Typography pt={2} variant="h6" component="div" >
                                         Ad Preview
                                    </Typography>
                                    <label className="text-sm">Video CTA</label>
                                    <Box className="previewWrapper">
                                        <img src={Banner2} className="width100"/>
                                        <div className="astons-img-box"> 
                                            <img src={astonImg} />
                                        </div>
                                    </Box>
                                    <hr />

                                    <Typography pt={2} pb={1} variant="h6" component="div" >
                                    Creative Specifications
                                    </Typography>
                                    <Box>
                                        <p className="text-para mb-0"> Dimensions: 1920 x 1080 (16:9)</p>
                                        <p className="text-para mb-0" > File type: MP4</p>
                                        <p className="text-para mb-0"> Max file size: 500 MB </p>
                                        <p className="text-para mb-0"> Frame rate: 25 - 30 fps </p>
                                        <p className="text-para mb-0"> Bit rate: 50Mbit/s CBR  </p>
                                        <p className="text-para mb-0"> Video codec: H264 </p>
                                        <p className="text-para mb-0"> Audio codec: AAC LC  </p>
                                        <p className="text-para mb-0"> Sound: Stereo track 1 & 2, 10dp to -12dp4 </p>
                                    </Box>

                                    <Typography pt={3} pb={1} variant="h6" component="div" className="text-danger">
                                    LOGO on Video Creative Specifications 
                                    </Typography>
                                    <Box>
                                        <p className="text-para mb-0"> Logo on video creatives should be placed only in the <b className="text-danger">Top LEFT Corner</b>,
                                         leaving a margin of 30px from top and 30px from left</p>
                                        <p className="text-para mb-0 mt-2" > The Logo in the video must be in the dimensions of <b className="text-danger">250px x 100px</b></p>
                                       
                                    </Box>
                                </CardContent>
                                
                            </Box>
                        </Grid>

                        <Grid xs={12} px={3} mb={3} textAlign="right">
                            <Box className="mb-3">
                                <Button className="btn btn-primary">Save As Draft</Button>
                                <Button className="btn btn-success mx-2" onClick={() => checkValidation()}>Submit For Review</Button>
                            </Box>
                        </Grid>
                    </Grid>
                    </Card>
                </Box>
                <Box pt={2} px={2} lineHeight={1.25}>
               
            </Box>
            <Footer />
        </Container>
    </DashboardLayout>
  );
}

export default Astons;
