import React, { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom'


import logo from "assets/images/logos/itw-logo.png"
import 'pages/common.css'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function MediaPlanExcelPreview(props) {
    const location = useLocation();

      function formatDate1(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${da} ${mo}, ${ye}`
        return newDate;
    }

      const formatNumbers = (num, val) => {
        let numbers;
        let char;
        if (num < 1000) { numbers = num; char = '' }
        else if (num >= 1000 && num < 100000) { numbers = (num / 1000); char = ' K' }
        else if (num >= 100000 && num < 10000000) { numbers = (num / 100000); char = ' L' }
        else if (num >= 10000000) { numbers = (num / 10000000); char = ' CR' }
        return numbers.toFixed(1) + char
    }

    function numberFormatUS(x) {
        let nf = new Intl.NumberFormat('en-US');
        return nf.format(x);
    }

    function numberFormatIN(x) {
        let nf = new Intl.NumberFormat('en-IN');
        return nf.format(x);
    }

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
           splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        return splitStr.join(' '); 
     }

    //   console.log("props.allForms",props.allForms)
    return (
            <div id="excelTable" style={{display: 'block'}}>
                <table class="table-small">
                    <tr>
                        <td>
                            <table>
                                <tr>
                                    <th style={{width: '200px',backgroundColor: "#fbd256", padding: "10px", height: "27px", fontSize: "10px", fontWeight: 200, border: "thin solid #444"}}><b>Client Name</b></th>
                                    <th style={{width: '200px',padding: "10px", height: "27px", fontSize: "10px", fontWeight: 200, border: "thin solid #444"}}><b>{props.brandDetails.brandName}</b></th>
                                </tr>
                                <tr>
                                    <th style={{width: '200px',backgroundColor: "#fbd256", padding: "10px", height: "27px", fontSize: "10px", fontWeight: 200, border: "thin solid #444"}}><b>Agency</b></th>
                                    <th style={{width: '200px',padding: "10px", height: "27px", fontSize: "10px", fontWeight: 200, border: "thin solid #444"}}><b>ITW Consulting Pvt Ltd</b></th>
                                </tr>
                                <tr>
                                    <th style={{width: '200px',backgroundColor: "#fbd256", padding: "10px", height: "27px", fontSize: "10px", fontWeight: 200, border: "thin solid #444"}}><b>Property</b></th>
                                    <th style={{width: '200px',padding: "10px", height: "27px", fontSize: "10px", fontWeight: 200, border: "thin solid #444"}}><b>Sony LIV</b></th>
                                </tr>
                            </table>
                        </td>
                        <td align="center" colspan="14">
                            <table>
                                <tr>
                                    <td><img style={{width: '120px',marginLeft: '25px'}} src={logo} /></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    
                </table>
                <table>
                    <tr><td colspan="16" rowspan="3">&nbsp;</td></tr>
                </table>
                <table>
                {props.allForms?.map((x,i)=> 
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td style={{backgroundColor: "#fbd256", height: "27px"}} colspan="16">&nbsp; </td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor: "#fbd256", height: "27px", width: "27px"}}>&nbsp;</td>
                                    <th style={{border: "thin solid #444", backgroundColor: "#ffecb3", height: "27px", fontWeight: 200,fontSize: "11px", textAlign: "center"}} colspan="14">
                                    <b>{x?.forms[0].optionPackageName} - {props.brandDetails.tournamentName !== null ? props.brandDetails.tournamentName : ""} | {x?.outlay !== null ? formatNumbers(x?.outlay) : 0} | {x?.forms[0].optionHeading} {x.id + 1}</b>
                                    </th>
                                    <td style={{backgroundColor: "#fbd256", height: "27px", width: "27px"}}>&nbsp;</td>
                                </tr>
                                
                                <tr>
                                    <td  style={{backgroundColor: "#fbd256", height: "27px", width: "27px"}}>&nbsp;</td>
                                    <th style={{width: 130,textAlign: "center",backgroundColor: "#ffc001", fontSize: "10px", fontWeight: 400,border: "thin solid #444", height: "27px", fontWeight: 500}}><b>Ad Format</b></th>
                                    <th style={{textAlign: "center",backgroundColor: "#ffc001", fontSize: "10px", fontWeight: 400,border: "thin solid #444", height: "27px", fontWeight: 500}}><b>Edit length</b></th>
                                    <th style={{textAlign: "center",backgroundColor: "#ffc001", fontSize: "10px", fontWeight: 400,border: "thin solid #444", height: "27px", fontWeight: 500}}><b>Targeting</b></th>
                                    <th style={{width: 130,textAlign: "center",backgroundColor: "#ffc001", fontSize: "10px", fontWeight: 400,border: "thin solid #444", height: "27px", fontWeight: 500}}><b>Duration</b></th>
                                    <th style={{textAlign: "center",backgroundColor: "#ffc001", fontSize: "10px", fontWeight: 400,border: "thin solid #444", height: "27px", fontWeight: 500}}><b>Start Date</b></th>
                                    <th style={{textAlign: "center",backgroundColor: "#ffc001", fontSize: "10px", fontWeight: 400,border: "thin solid #444", height: "27px", fontWeight: 500}}><b>End Date</b></th>
                                    <th style={{textAlign: "center",backgroundColor: "#ffc001", fontSize: "10px", fontWeight: 400,border: "thin solid #444", height: "27px", fontWeight: 500}}><b>Rate Card CPM 10s</b></th>
                                    <th style={{textAlign: "center",backgroundColor: "#ffc001", fontSize: "10px", fontWeight: 400,border: "thin solid #444", height: "27px", fontWeight: 500}}><b>Rate Card CPM</b></th>
                                    <th style={{textAlign: "center",backgroundColor: "#ffc001", fontSize: "10px", fontWeight: 400,border: "thin solid #444", height: "27px", fontWeight: 500}}><b>Discount %</b></th>
                                    <th style={{textAlign: "center",backgroundColor: "#ffc001", fontSize: "10px", fontWeight: 400,border: "thin solid #444", height: "27px", fontWeight: 500}}><b>Discounted CPM</b></th>
                                    <th style={{textAlign: "center",backgroundColor: "#ffc001", fontSize: "10px", fontWeight: 400,border: "thin solid #444", height: "27px", fontWeight: 500}}><b>Booked Impressions</b></th>
                                    <th style={{textAlign: "center",backgroundColor: "#ffc001", fontSize: "10px", fontWeight: 400,border: "thin solid #444", height: "27px", fontWeight: 500}}><b>Discounted Outlay</b> </th>
                                    <th style={{textAlign: "center",backgroundColor: "#ffc001", fontSize: "10px", fontWeight: 400,border: "thin solid #444", height: "27px", fontWeight: 500}}><b>Non Discounted Outlay</b></th>
                                    <th style={{textAlign: "center",backgroundColor: "#ffc001", fontSize: "10px", fontWeight: 400,border: "thin solid #444", height: "27px", fontWeight: 500}}><b>Estimated Reach</b></th>
                                    <td style={{textAlign: "center",backgroundColor: "#fbd256", height: "27px", width: "27px"}}>&nbsp;</td>
                                </tr>
                                {x?.forms?.map((item, index) =>
                                <tr>
                                    <th style={{backgroundColor: "#fbd256", height: "27px", width: "27px"}}>&nbsp;</th>
                                    <th  style={{width: 130,textAlign: "center",width: 130,padding: "10px", height: "27px", fontSize: "11px", fontWeight: 400, border: "thin solid #444"}}>{item?.adFormat}</th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 400, border: "thin solid #444"}}>{item?.editLength}</th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 400, border: "thin solid #444"}}>{item.target === "ROS" ? "ROS" : item.targetRegion}</th>
                                    <th  style={{textAlign: "center",padding: "10px",width: 130, height: "27px", fontSize: "11px", fontWeight: 400, border: "thin solid #444"}}>{item.duration === "ODI_only" ? "ODI Only" : item.duration === "custom" ? titleCase(item.customDuration) : titleCase(item.duration.split("_").join(' '))}</th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 400, border: "thin solid #444"}}><div style={{width: 'max-content'}}>{item?.startDate ? formatDate1(item.startDate) : formatDate1(new Date())}</div></th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 400, border: "thin solid #444"}}><div style={{width: 'max-content'}}>{item?.endDate ? formatDate1(item.endDate) : formatDate1(new Date())}</div></th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 400, border: "thin solid #444"}}>{item.rateCardCpm10}</th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 400, border: "thin solid #444"}}>{item.rateCardCpm}</th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 400, border: "thin solid #444"}}>{item.discount}</th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 400, border: "thin solid #444"}}>{item.discountedCpm}</th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 400, border: "thin solid #444"}}>{item.adFormat === "Squeeze Up" ? item.impressions : numberFormatUS(item.impressions)}</th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 400, border: "thin solid #444"}}>{numberFormatIN(item.discountedOutlay)}</th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 400, border: "thin solid #444"}}>{numberFormatIN(item.nonDiscountedOutlay)}</th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 400, border: "thin solid #444"}}>{item.reach}</th>
                                    <th style={{backgroundColor: "#fbd256", height: "27px", width: "27px"}}>&nbsp;</th>
                                </tr>
                                )}
                                {/* <tr>
                                    <td style={{backgroundColor: "#fbd256", height: "1px", width: "27px", lineHeight: "1px"}}>&nbsp;</td>
                                    <td style={{backgroundColor: "#fff", height: "1px", border: "thin solid #444", lineHeight: "1px"}} colspan="14">&nbsp; </td>
                                    <td style={{backgroundColor: "#fbd256", height: "1px", width: "27px", lineHeight: "1px"}}>&nbsp;</td>
                                </tr> */}

                                <tr>
                                    <th style={{textAlign: "center",backgroundColor: "#fbd256", height: "27px", width: "27px"}}>&nbsp;</th>
                                    <th style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}} colspan="10" ><b>Total</b></th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}><b>{x.forms?.length > 0 ? numberFormatUS(x.forms?.map(item => /^\d+$/.test(item?.impressions) !== true ? 1 :  item?.impressions).reduce((partialSum, a) => Math.round(Number(partialSum) + Number(a)))) : 0}</b></th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}><b>{x.forms?.length > 0 ? numberFormatIN(x.forms?.map(item => item?.discountedOutlay).reduce((partialSum, a) => Math.round(Number(partialSum) + Number(a)))) : 0}</b></th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}><b>{x.forms?.length > 0 ? numberFormatIN(x.forms?.map(item => item?.nonDiscountedOutlay).reduce((partialSum, a) => Math.round(Number(partialSum) + Number(a)))) : 0}</b></th>
                                    <th  style={{textAlign: "center",padding: "10px", height: "27px", fontSize: "11px", fontWeight: 200, border: "thin solid #444"}}><b></b></th>
                                    <th style={{backgroundColor: "#fbd256", height: "27px", width: "27px"}}>&nbsp;</th>
                                </tr>
                            
                                <tr>
                                    <td style={{backgroundColor: "#fbd256", height: "27px"}} colspan="16">&nbsp; </td>
                                </tr>
                            </table>
                            <table>
                                <tr><td colspan="16" rowspan="3">&nbsp;</td></tr>
                            </table>
                        </td>
                    </tr>
                )}
                </table>

                <div>
                    <ul>
                        <li><i><b>Note:</b></i></li>
                        <li><i><b> *100% Advance payment</b></i></li>
                        <li><i><b> *Applicable taxes extra.</b></i></li>
                        <li><i><b> *Creatives & CTA details to be shared 96 hours before the campaign commencement</b></i></li>
                        <li><i><b> *Campaign reports shall be shared on weekly basis from ad serving platform </b></i></li>
                        <li><i><b> *Daily tracking and 3rd party validation can be implemented (Amazon Sizmek) Service fee will be charged INR 2/CPM.</b></i></li>
                        <li><i><b> *Tracking can be enabled using Client attribution platform integrated with SonyLiv/Hotstar </b></i></li>
                        <li><i><b> *Invoice shall be raised post delivery of the campaign or Monthly basis, which ever earlier</b></i></li>    
                    </ul>
                </div>
            </div>           
    );
}

export default MediaPlanExcelPreview;