/* eslint-disable default-case */
import API from 'apiConfig';
import createDataContext from './createDataContext';

const authReducer = (state, action) => {
    switch (action.type) {
        default:
            return state;
        case 'signin':
            return {errorMessage:'', login_data : action.payload};
        case 'authStatus':
            return {...state, isSignedIn:true};    
    }
};

const signin = (dispatch) => {
    return async (userDetails) => {
        try {
            const response = await API.post('login/login_auth', userDetails ,{ withCredentials: false });
            if (response.data.status){
                localStorage.setItem('token', JSON.stringify(response.data.token));
                dispatch({type:'authStatus', payload:true})
                return response
            }
            else {
                return response
            }
        }
        catch (error) {
            alert(error && error.response && error.response.data && error.response.data.message)
        }
    }
}



const logout = (dispatch) => {
    return async () => {
        localStorage.clear();
        try {
            // const response = await API.get('user/seller/logout');
            dispatch({type:'authStatus', payload:false})
            // return response.data.status
            window.location.reload();
        }
        catch (err) {
            return null
        }
    }
}

const isSignedIn = localStorage.getItem('token') !== null ?  true : false;

export const { Provider, Context} = createDataContext(
    authReducer,
    {signin,logout},
    {isSignedIn: isSignedIn},
)