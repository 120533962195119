// Material Dashboard 2 React layouts
import Dashboard from "pages/Dashboard";
import ViewBrand from "pages/brand/ViewBrand";
import CreateBrand from "pages/brand/CreateBrand";
import ViewSeries from "pages/series/ViewSeries";
import BrandList from "pages/series/BrandList"
import LineItemList from "pages/series/LineItemList"
import SignUp from "pages/authentication/SignUp"
// import ResetPassword from "pages/authentication/ResetPassword"
import ChangePassword from "pages/account/ChangePassword"
import Astons from "pages/end-user/Astons"
import Profile from "pages/account/Profile"
import { Context as AuthContext } from "./context/AuthContext";


// @mui icons
import Icon from "@mui/material/Icon";
import PageNotFound from "pages/NotFoundPage";
import { useContext } from "react";
import MediaPlanSheet from "pages/media-plan-sheet/MediaPlanSheet";
import Bag from "pages/bag/Bag";
import UpdateMediaPlanSheet from "pages/media-plan-sheet/UpdateMediaPlanSheet";
import MediaPlanExcelSheet from "pages/media-plan-sheet/MediaPlanExcelSheet";

const routes = [
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: <Dashboard />,
  // },
  {
    type: "collapse",
    name: "Media Plan Sheet",
    key: "media-plan-sheet",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/media-plan-sheet",
    component: <MediaPlanSheet />,
  },
  {
    type: "collapse",
    name: "Brand",
    key: "viewBrand",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/view-brand",
    component: <ViewBrand />,
  },
  {
    type: "collapse",
    name: "Series",
    key: "viewSeries",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/view-series",
    component: <ViewSeries />,
  },
  {
    route: "/create-brand",
    key: "create-brand",
    component: <CreateBrand />,
  },
  {
    route: "/excel",
    key: "excel",
    component: <MediaPlanExcelSheet />,
  },
  {
    route: "/brand-list",
    key: "brand-list",
    component: <BrandList />,
  },
  {
    route: "/line-item-list",
    key: "line-item-list",
    component: <LineItemList />,
  },
  {
    route: "/profile",
    key: "profile",
    component: <Profile />,
  },
  // {
  //   type: "collapse",
  //   name: "Media Plan Sheet",
  //   key: "media-plan-sheet",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/media-plan-sheet",
  //   component: <MediaPlanSheet />,
  // },
  {
    route: "/update-media-plan-sheet",
    key: "update-media-plan-sheet",
    component: <UpdateMediaPlanSheet />,
  },
  {
    type: "collapse",
    name: "Bag",
    key: "bag",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/bag",
    component: <Bag />,
  },
  // {
  //   route: "/sign-up",
  //   key: "sign-up",

  //   component: <SignUp />,
  // },
  // {
  //   route: "/reset-password",
  //   key: "reset-password",

  //   component: <ResetPassword />,
  // },
  {
    route: "/change-password",
    key: "change-password",

    component: <ChangePassword />,
  },
  // {
  //   route:"/",
  //   key: "/",

  //   component: <SignUp />
  // },
  // {
  //   route:'*',
  //   key: "*",
  //   component: <PageNotFound />
  // },
]

export default routes;
