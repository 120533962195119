export const creativeStatusLabel = (id) => {
    let status = ''
    switch (id) {
        case "1":
            return status = 'Pending';
        case "2":
            return status = 'In Review';
        case "3":
            return status = 'Approved';
        case "4":
            return status = 'QC Rejected';
        case "5":
            return status = 'In Draft';
    }
}

export const campaignStatusLabel = (id) => {
    let status = ''
    switch (id) {
        case "1":
            return status = 'PENDING';
        case "2":
            return status = 'READY';
        case "3":
            return status = 'COMPLETED';
        case "4":
            return status = 'LIVE';
        case "5":
            return status = 'CLOSED';
    }
}

export function DateFormat(dateString) {
    if (dateString) {
  
      const date = new Date(dateString);
      let arr = dateString.toString().split(' ')
      let times = arr[1].split(':')
  
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
  
      const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  
      const day = date.getDay();
      const dates = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();
  
  
  
      return (
        dates + ' ' + monthNames[monthIndex] + ' ' + year
      );
    }
  }