import React, { useState } from "react"
import { Container, Card, Box, TextField, Grid, CardContent, Typography, InputLabel, MenuItem, FormControl, Select, FormHelperText } from "@mui/material";
import 'pages/common.css'

// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";

import { Button, Row, Col } from 'react-bootstrap';
import { nameValidator, emailValidator, phoneValidator } from "Validation";
import API from "apiConfig";
import { brandNameValidator } from "Validation";

function CreateBrand() {
    const [brandDetails, setBrandDetails] = useState({
        brandName: null, brandPocName: null,
        brandPocEmail: null, brandPocPhone: null,
        brandSalesPocName: null, brandSalesPocEmail: null,
        brandSalesPocPhone: null, digitalPocName: null,
        digitalPocEmail: null, digitalPocPhone: null,
        region: null
    });
    const [errorDetails, setErrorDetails] = useState({
        brandName: null, brandPocName: null,
        brandPocEmail: null, brandPocPhone: null,
        brandSalesPocName: null, brandSalesPocEmail: null,
        brandSalesPocPhone: null, digitalPocName: null,
        digitalPocEmail: null, digitalPocPhone: null,
        region: null
    });

    const handleInputChange = (e) => {
        setBrandDetails({ ...brandDetails, [e.target.name]: e.target.value });
        setErrorDetails({ ...errorDetails, [e.target.name]: null });
    }

    const onCreateBrand = async (item) => {
        let dataToSend = {
            "brand_name": item.brandName,
            "brand_contact_name": item.brandPocName,
            "brand_contact_email": item.brandPocEmail,
            "brand_contact_phone": item.brandPocPhone,
            "itw_contact_name": item.brandSalesPocName,
            "itw_contact_email": item.brandSalesPocEmail,
            "itw_contact_phone": item.brandSalesPocPhone,
            "digital_io_contact_name": item.digitalPocName,
            "digital_io_contact_email": item.digitalPocEmail,
            "digital_io_contact_phone": item.digitalPocPhone,
            "region": item.region
        }
        console.log("dataToSend", dataToSend);
        const response = await API.post('brand/create_brand', dataToSend);
        if (response.data.status) {
            alert(`${response.data.message}`);
            window.location.reload();
        }
        else if (!response.data.status) {
            alert(`${response.data.message.replace(/<\/?[^>]+(>|$)/g, "")}`);
        }
        else {
            console.log("Error")
        }
    }

    const checkValidation = () => {
        let brandName = brandNameValidator(brandDetails.brandName);
        // let brandPocName = nameValidator(brandDetails.brandPocName);
        // let brandPocEmail = emailValidator(brandDetails.brandPocEmail);
        // let brandPocPhone = phoneValidator(brandDetails.brandPocPhone);
        let brandSalesPocName = brandDetails.brandSalesPocName !== null ? nameValidator(brandDetails.brandSalesPocName) : null;
        let brandSalesPocEmail = brandDetails.brandSalesPocEmail !== null ? emailValidator(brandDetails.brandSalesPocEmail) : null;
       // let brandSalesPocPhone = phoneValidator(brandDetails.brandSalesPocPhone);
        let digitalSalesPocName = brandDetails.digitalPocName !== null ? nameValidator(brandDetails.digitalPocName) : null;
        let digitalSalesPocEmail = brandDetails.digitalPocEmail !== null ? emailValidator(brandDetails.digitalPocEmail) : null;
       // let digitalSalesPocPhone = phoneValidator(brandDetails.digitalPocPhone);

        if (brandName || brandSalesPocName || brandSalesPocEmail || digitalSalesPocName || digitalSalesPocEmail || brandDetails.region === null) {
            setErrorDetails({
                ...errorDetails,
                brandName: brandName,
                // brandPocName: brandPocName,
                // brandPocEmail: brandPocEmail,
                // brandPocPhone: brandPocPhone,
                brandSalesPocName: brandSalesPocName,
                brandSalesPocEmail: brandSalesPocEmail,
               // brandSalesPocPhone: brandSalesPocPhone,
                digitalPocName: digitalSalesPocName,
                digitalPocEmail: digitalSalesPocEmail,
                region: brandDetails.region === null ? "Please select region" : null
            })
            return;
        }
        else if (brandDetails.region !== "Bangalore" && brandDetails.region !== "Delhi" && brandDetails.region !== "Mumbai") {
            setErrorDetails({
                ...errorDetails,
                region: "Please select region"
            })
            return;
        }
        else {
            onCreateBrand(brandDetails);
        }
    }

    const handleBrandPocPhoneChange = (e) => {
        setErrorDetails({ ...errorDetails, brandPocPhone: null });
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setBrandDetails({ ...brandDetails, brandPocPhone: onlyNums })
        } else if (onlyNums.length === 10) {
            const number = onlyNums;
            setBrandDetails({ ...brandDetails, brandPocPhone: number })
        }
    }

    const handleBrandSalesPocPhoneChange = (e) => {
        setErrorDetails({ ...errorDetails, brandSalesPocPhone: null });
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setBrandDetails({ ...brandDetails, brandSalesPocPhone: onlyNums })
        } else if (onlyNums.length === 10) {
            const number = onlyNums;
            setBrandDetails({ ...brandDetails, brandSalesPocPhone: number })
        }
    }

    const handleDigitalSalesPocPhoneChange = (e) => {
        setErrorDetails({ ...errorDetails, digitalPocPhone: null });
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setBrandDetails({ ...brandDetails, digitalPocPhone: onlyNums })
        } else if (onlyNums.length === 10) {
            const number = onlyNums;
            setBrandDetails({ ...brandDetails, digitalPocPhone: number })
        }
    }

    return (

        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <Grid container>
                    <Grid item xs={12} md={12} xl={12}>
                        <Box>
                            <CardContent>
                                <Typography pt={2} variant="h4" component="div" className="primary-text">
                                    Create Brand
                                </Typography>

                                <Box className="box-container mt-2">
                                    <Typography variant="h6" component="div">
                                        Brand Name
                                    </Typography>
                                    <Box component="form" noValidate autoComplete="off" >
                                        <Row>
                                            <Col item xs={12} md={6} >
                                                <TextField
                                                    className="form-control mt-3"
                                                    id="outlined-basic"
                                                    label="Brand Name"
                                                    variant="outlined"
                                                    name="brandName"
                                                    inputProps={{ maxLength: 25 }}
                                                    onChange={handleInputChange}
                                                    value={brandDetails.brandName}
                                                    error={errorDetails.brandName}
                                                    helperText={errorDetails.brandName}
                                                    autoComplete="off"
                                                />
                                            </Col>
                                        </Row>
                                    </Box>
                                </Box>

                                <Box className="box-container mt-3">
                                    <Typography variant="h6" component="div">
                                        Brand POC
                                    </Typography>
                                    <Box component="form" noValidate autoComplete="off" >
                                        <Row>
                                            <Col item xs={12} md={6} >
                                                <TextField
                                                    type="text"
                                                    className="form-control mt-3"
                                                    id="outlined-basic"
                                                    label="Name"
                                                    variant="outlined"
                                                    name="brandPocName"
                                                    inputProps={{ maxLength: 25 }}
                                                    onChange={handleInputChange}
                                                    value={brandDetails.brandPocName}
                                                    error={errorDetails.brandPocName}
                                                    helperText={errorDetails.brandPocName}
                                                    autoComplete="off"
                                                />
                                            </Col>
                                            <Col item xs={12} md={6} >
                                                <TextField
                                                    type="email"
                                                    className="form-control mt-3"
                                                    id="outlined-basic"
                                                    label="Email ID"
                                                    variant="outlined"
                                                    name="brandPocEmail"
                                                    onChange={handleInputChange}
                                                    value={brandDetails.brandPocEmail}
                                                    error={errorDetails.brandPocEmail}
                                                    helperText={errorDetails.brandPocEmail}
                                                    autoComplete="off"
                                                />
                                            </Col>

                                            <Col item xs={12} md={6}>
                                                <TextField
                                                    type="text"
                                                    className="form-control mt-3"
                                                    id="outlined-basic"
                                                    label="Phone Number"
                                                    variant="outlined"
                                                    name="brandPocPhone"
                                                    onChange={handleBrandPocPhoneChange}
                                                    value={brandDetails.brandPocPhone}
                                                    error={errorDetails.brandPocPhone}
                                                    helperText={errorDetails.brandPocPhone}
                                                    autoComplete="off"
                                                />
                                            </Col>
                                        </Row>
                                    </Box>
                                </Box>

                                <Box className="box-container mt-3">
                                    <Typography variant="h6" component="div">
                                    ITW Sales POC
                                    </Typography>
                                    <Box component="form" noValidate autoComplete="off" >
                                        <Row>
                                            <Col item xs={12} md={6} >
                                                <TextField
                                                    type="text"
                                                    className="form-control mt-3"
                                                    id="outlined-basic"
                                                    label="Name"
                                                    variant="outlined"
                                                    name="brandSalesPocName"
                                                    inputProps={{ maxLength: 25 }}
                                                    onChange={handleInputChange}
                                                    value={brandDetails.brandSalesPocName}
                                                    error={errorDetails.brandSalesPocName}
                                                    helperText={errorDetails.brandSalesPocName}
                                                    autoComplete="off"
                                                />
                                            </Col>
                                            <Col item xs={12} md={6} >
                                                <TextField
                                                    type="email"
                                                    className="form-control mt-3"
                                                    id="outlined-basic"
                                                    label="Email ID"
                                                    variant="outlined"
                                                    name="brandSalesPocEmail"
                                                    onChange={handleInputChange}
                                                    value={brandDetails.brandSalesPocEmail}
                                                    error={errorDetails.brandSalesPocEmail}
                                                    helperText={errorDetails.brandSalesPocEmail}
                                                    autoComplete="off"
                                                />
                                            </Col>
                                            <Col item xs={12} md={6}>
                                                <TextField
                                                    type="text"
                                                    className="form-control mt-3"
                                                    id="outlined-basic"
                                                    label="Phone Number"
                                                    variant="outlined"
                                                    name="brandSalesPocPhone"
                                                    onChange={handleBrandSalesPocPhoneChange}
                                                    value={brandDetails.brandSalesPocPhone}
                                                    error={errorDetails.brandSalesPocPhone}
                                                    helperText={errorDetails.brandSalesPocPhone}
                                                    autoComplete="off"
                                                />
                                            </Col>
                                        </Row>
                                    </Box>
                                </Box>

                                <Box className="box-container mt-3">
                                    <Typography variant="h6" component="div">
                                    ITW IO Digitial POC
                                    </Typography>
                                    <Box component="form" noValidate autoComplete="off" >
                                        <Row>
                                            <Col item xs={12} md={6} >
                                                <TextField
                                                    type="text"
                                                    className="form-control mt-3"
                                                    id="outlined-basic"
                                                    label="Name"
                                                    variant="outlined"
                                                    name="digitalPocName"
                                                    inputProps={{ maxLength: 25 }}
                                                    onChange={handleInputChange}
                                                    value={brandDetails.digitalPocName}
                                                    error={errorDetails.digitalPocName}
                                                    helperText={errorDetails.digitalPocName}
                                                    autoComplete="off"
                                                />
                                            </Col>
                                            <Col item xs={12} md={6} >
                                                <TextField
                                                    type="email"
                                                    className="form-control mt-3"
                                                    id="outlined-basic"
                                                    label="Email ID"
                                                    variant="outlined"
                                                    name="digitalPocEmail"
                                                    onChange={handleInputChange}
                                                    value={brandDetails.digitalPocEmail}
                                                    error={errorDetails.digitalPocEmail}
                                                    helperText={errorDetails.digitalPocEmail}
                                                    autoComplete="off"
                                                />
                                            </Col>
                                            <Col item xs={12} md={6}>
                                                <TextField
                                                    type="text"
                                                    className="form-control mt-3"
                                                    id="outlined-basic"
                                                    label="Phone Number"
                                                    variant="outlined"
                                                    name="digitalPocPhone"
                                                    onChange={handleDigitalSalesPocPhoneChange}
                                                    value={brandDetails.digitalPocPhone}
                                                    error={errorDetails.digitalPocPhone}
                                                    helperText={errorDetails.digitalPocPhone}
                                                    autoComplete="off"
                                                />
                                            </Col>
                                        </Row>
                                    </Box>
                                </Box>

                                <Box className="box-container mt-3">
                                    <Typography variant="h6" component="div">
                                        Region
                                    </Typography>
                                    <Box component="form" mt={2} noValidate autoComplete="off" >
                                        <Row>
                                            <Col item xs={12} md={6} >
                                                <FormControl fullWidth>
                                                    <InputLabel className="overflow-visible" id="demo-simple-select-label">Region</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Select Region"
                                                        name="region"
                                                        onChange={handleInputChange}
                                                        value={brandDetails.region}
                                                        error={errorDetails.region}
                                                    >
                                                        <MenuItem value="Bangalore">Bangalore</MenuItem>
                                                        <MenuItem value="Delhi">Delhi</MenuItem>
                                                        <MenuItem value="Mumbai">Mumbai</MenuItem>
                                                    </Select>
                                                    <FormHelperText error={errorDetails.region}>{errorDetails.region}</FormHelperText>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </Box>
                                </Box>

                                <Box mt={3}>
                                    <Button size="small" onClick={() => checkValidation()}>Create Brand</Button>
                                </Box>

                                <hr />
                            </CardContent>

                        </Box>


                    </Grid>
                </Grid>
                <Box pt={2} px={2} lineHeight={1.25}>
                </Box>
            </Card>
            <Footer />
        </DashboardLayout>
    );
}

export default CreateBrand;
