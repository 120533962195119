import React, {useState, useEffect} from "react"
import { Link } from "react-router-dom";
import { TextField, Grid, Card, Tabs, Tab, Typography, Box, Modal, LinearProgress, Tooltip , FormHelperText} from "@mui/material";
import { Button, Row, Col } from 'react-bootstrap';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import 'pages/common.css';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import DatePicker from 'react-date-picker';


import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import DataTable from "common/Tables/DataTable";
import PropTypes from 'prop-types';
import { seriesNameValidator } from "Validation";
import API from "apiConfig";

function ViewSeries() {
    const resultPerPage = 20;
    const { columns, rows } = authorsTableData();
    const { columns: pColumns, rows: pRows } = projectsTableData();
    const [open, setOpen] = React.useState(false);
    const [spSeriesName, setSpSeriesName] = React.useState("");
    const [spSeriesNameError, setSpSeriesNameError] = React.useState(null);
    const [entSeriesName, setEntSeriesName] = React.useState("");
    const [entSeriesNameError, setEntSeriesNameError] = React.useState(null);
    const [tabValue, setTabValue] = React.useState("Sports List");
    const [createTabValue, setCreateTabValue] = React.useState("Sports");
    const [sportsList, setSportsList] = useState([]);
    const [entertainmentList, setEntertainmentList] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startDateError, setStartDateError] = useState(null);
    const [endDateError, setEndDateError] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [value, setValue] = React.useState(0);
    const [createValue, setCreateValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setTabValue(event.target.innerText);
        // setSpSeriesName("");
        // setSpSeriesNameError(null);
        // setEntSeriesName("");
        // setEntSeriesNameError(null);
    };

    const handleCreateSeriesChange = (event, newValue) => {
        setCreateValue(newValue);
        setCreateTabValue(event.target.innerText);
        setSpSeriesName("");
        setSpSeriesNameError(null);
        setEntSeriesName("");
        setEntSeriesNameError(null);
    };

    useEffect(() => {
        getEventDetails();
    }, [tabValue]);

    //function to get brand details
    const getEventDetails = async (pageNo) => {
        setIsLoading(true);
        let pageCount = (pageNo !== undefined && pageNo !== null) ? Number((pageNo - 1)*resultPerPage) : 0;
        let activePageIndex = (pageNo !== undefined && pageNo !== null) ? pageNo : 1 ;
        if (tabValue === "Sports List") {
            try {
                const response = await API.get(`series/view_sport_series/${pageCount}`);
                if (response.data.status) {
                    console.log("ResponseSports", response.data.data);
                    console.log("ResponseSports123", response.data.data.total_records);
                    let total_records = response.data.data.total_records;
                    setTotalRecords(Math.ceil(JSON.parse(total_records) / resultPerPage));
                    setSportsList(response.data.data.data);
                    setCurrentPage(activePageIndex);
                    setIsLoading(false);
                }
                else {
                    console.log("Error");
                    setIsLoading(false);
                    setCurrentPage(1);
                    alert("Something went wrong! Please try again");
                    window.location.reload();
                }
            }
            catch (error) {
                alert(error && error.response && error.response.data && error.response.data.message)
            }
        }
        else {
            try {
                const response = await API.get(`series/view_entertainment_series/${pageCount}`);
                if (response.data.status) {
                    console.log("ResponseEntertainment", response.data.data);
                    let total_records = response.data.data.total_records;
                    setTotalRecords(Math.ceil(JSON.parse(total_records) / resultPerPage));
                    setEntertainmentList(response.data.data.data);
                    setCurrentPage(activePageIndex);
                    setIsLoading(false);
                }
                else {
                    console.log("Error");
                    setIsLoading(false);
                    setCurrentPage(1);
                    alert("Something went wrong! Please try again");
                    window.location.reload();
                }
            }
            catch (error) {
                alert(error && error.response && error.response.data && error.response.data.message)
            }
        }
    }

    const checkValidation = () => {
        let sportsSeriesNameError = seriesNameValidator(spSeriesName);
        let startDateErr = startDate === "" ? "Enter start date" : null;
        let endDateErr = endDate === "" ? "Enter end date" : null;
        let entertainmentSeriesNameError = seriesNameValidator(entSeriesName);
        if (createTabValue === "Sports") {
            if (sportsSeriesNameError || startDateErr || endDateErr) {
                setSpSeriesNameError(sportsSeriesNameError);
                setStartDateError(startDateErr);
                setEndDateError(endDateErr);
                return;
            }
            else {
                onCreateSeries("Sports");
            }
        }
        else {
            if (entertainmentSeriesNameError) {
                setEntSeriesNameError(entertainmentSeriesNameError);
                return;
            }
            else {
                onCreateSeries("Entertainment");
            }
        }
    }
    const onCreateSeries = async (series) => {
        setOpen(false);
        setIsLoading(true);
        let dataToSend = {
            "series_type": createTabValue, // sports / entertainment
            "series_name": createTabValue === "Sports" ? spSeriesName : entSeriesName
        }
        if (series === "Sports") {
            dataToSend = {
                ...dataToSend,
                start_date: startDate,
                end_date: endDate
            }
        }
        console.log("dataTosend", dataToSend);
        const response = await API.post('series/create_series',dataToSend);
        if (response.data.status) {
            alert(`${response.data.message}`);
            window.location.reload();
            setIsLoading(false);
        }
        else if (!response.data.status) {
            alert(`${response.data.message.replace(/<\/?[^>]+(>|$)/g, "")}`);
            setIsLoading(false);
        }
        else {
            console.log("Error");
            setIsLoading(false);
            alert("Something went wrong! Please try again");
            window.location.reload();
        }
    }

    function formatDate(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${da}-${mo}-${ye}`
        return newDate;
    }

    return (


        <DashboardLayout className="abc">
            <DashboardNavbar />
            <Card pb="3">
                <MDBox className="text-right" px={2} pt={3}>
                    <Button size="small" onClick={handleOpen}>Create Series</Button>
                </MDBox>

                <Box pt={3} sx={{ width: '100%' }}>
                    <Box px={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Sports List" {...a11yProps(0)} />
                            <Tab label="Entertainment List" {...a11yProps(1)} />
                        </Tabs>
                    </Box>

                    <TabPanel px={2} value={value} index={0}>

                        <MDBox pt={3} pb={3}>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>

                                    <Card>
                                        <MDBox
                                            mx={2}
                                            mt={-3}
                                            py={3}
                                            px={2}
                                            variant="gradient"
                                            bgColor="info"
                                            borderRadius="lg"
                                            coloredShadow="info"
                                        >
                                            <MDTypography variant="h6" color="white">
                                                Sports List
                                            </MDTypography>
                                        </MDBox>
                                        {!isLoading ?
                                        <MDBox>
                                            <DataTable
                                                    entriesPerPage={false}
                                                    pagination={false}
                                                    showTotalEntries={false}
                                                table={{
                                                    columns: [
                                                        { Header: "Series Name ", accessor: "name" },
                                                        { Header: "Start Date ", accessor: "startDate", align: "center"},
                                                        { Header: "End Date ", accessor: "endDate", align: "center" },
                                                        // { Header: "Action", accessor: "action" },
                                                    ],
                                                    rows: 
                                                    sportsList.map(item => (
                                                        {
                                                            name: item.series_name,
                                                            startDate: item.start_date? formatDate(item.start_date) : "-",
                                                            endDate: item.end_date? formatDate(item.end_date) : "-",
                                                            // action: [(
                                                            //     <Link to="#" >
                                                            //         <Tooltip title="Edit" arrow>
                                                            //             <Button className="btn btn-primary btn-sm">
                                                            //                 <ModeOutlinedIcon />
                                                            //             </Button>
                                                            //         </Tooltip>
                                                            //     </Link>
                                                            // ), 
                                                            // ( <Link to="/brand-list" state={{ item: item }} className="px-3">
                                                            //         <Tooltip title="View" place={'top'} arrow>
                                                            //             <Button className="btn btn-success btn-sm">
                                                            //                 <RemoveRedEyeIcon />
                                                            //             </Button>
                                                            //         </Tooltip>
                                                            //     </Link>
                                                            // )],
                                                        }
                                                    ))
                                                }}
                                            />
                                             {totalRecords > 1 && (
                                             <Box className="py-3 mb-3" >
                                                <Stack spacing={2} alignItems="end">
                                                    <Pagination page={currentPage} count={totalRecords} onChange={(e, index)=> getEventDetails(index)} color="primary" />
                                                </Stack>
                                            </Box>
                                             )}
                                        </MDBox>
                                        :
                                        <Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                            <LinearProgress color="success" />
                                        </Stack>
                                        }
                                    </Card>
                                </Grid>
                            </Grid>
                        </MDBox>

                    </TabPanel>
                    <TabPanel px={2} value={value} index={1}>
                        <MDBox pt={3} pb={3}>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                {!isLoading ?
                                    <Card>
                                        <MDBox
                                            mx={2}
                                            mt={-3}
                                            py={3}
                                            px={2}
                                            variant="gradient"
                                            bgColor="info"
                                            borderRadius="lg"
                                            coloredShadow="info"
                                        >
                                            <MDTypography variant="h6" color="white">
                                                Entertainment List
                                            </MDTypography>
                                        </MDBox>

                                        <MDBox>
                                            <DataTable
                                             entriesPerPage={false}
                                             pagination={false}
                                             showTotalEntries={false}
                                                table={{
                                                    columns: [
                                                        { Header: "Series Name ", accessor: "name" },
                                                        { Header: "Action", accessor: "action" }
                                                    ],
                                                    rows: 
                                                    entertainmentList.map(item => (
                                                        {
                                                            name: item.series_name,
                                                            action: [(
                                                                <Link to="#" >
                                                                    <Tooltip title="Edit" arrow>
                                                                        <Button className="btn btn-primary btn-sm">
                                                                            <ModeOutlinedIcon />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Link>
                                                            ), 
                                                            (<Link to="/brand-list" state={{ item: item }} className="px-3">
                                                                    <Tooltip title="View" arrow>
                                                                        <Button className="btn btn-success btn-sm">
                                                                            <RemoveRedEyeIcon />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Link>
                                                            )]
                                                        }
                                                    ))
                                                }}
                                            />
                                            {totalRecords > 1 && (
                                            <Box className="py-3 mb-3" >
                                                <Stack spacing={2} alignItems="end">
                                                    <Pagination page={currentPage} count={totalRecords} onChange={(e, index)=> getEventDetails(index)} color="primary" />
                                                </Stack>
                                            </Box>
                                            )}
                                        </MDBox>
                                    </Card>
                                    :
                                    <Stack className="p-3" sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                        <LinearProgress color="success" />
                                    </Stack>
                                    }
                                </Grid>
                            </Grid>
                        </MDBox>
                    </TabPanel>


                </Box>
            </Card>



            <Modal
                className="modal-width"
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-body">

                    <Typography mb={2} variant="h5" component="div" className="primary-text">
                        Choose Series
                    </Typography>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={createValue} onChange={handleCreateSeriesChange} aria-label="basic tabs example">
                            <Tab label="Sports" {...a11yProps(0)} />
                            <Tab label="Entertainment" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel className="box-container p-1" value={createValue} index={0}>
                        <Box >
                            <Typography variant="h6" component="div">
                                Series Details
                            </Typography>

                            <Box component="form" noValidate autoComplete="off" >
                                <Row>
                                    <Col item xs={12} md={12} >
                                        <label className="text-xs">Series Name </label>
                                        <TextField
                                            className="form-control mt-1"
                                            id="outlined-basic"
                                            label="Series Name"
                                            variant="outlined"
                                            name="sportsSeriesName"
                                            onChange={(e) => { setSpSeriesName(e.target.value); setSpSeriesNameError(null) }}
                                            value={spSeriesName}
                                            error={spSeriesNameError}
                                            helperText={spSeriesNameError}
                                            autoComplete="off"
                                        />
                                    </Col>

                                    <Col item xs={12} md={6} mt={3}>
                                        <label className="text-xs">Start Date</label>
                                        <DatePicker
                                            className="form-control mt-1"
                                            onChange={(date) => { setStartDate(date); setStartDateError(null) }}
                                            value={startDate}
                                            minDate={new Date()}
                                            format="dd-MMM yyyy"
                                        />
                                        <FormHelperText error={startDateError}>{startDateError}</FormHelperText>
                                    </Col>

                                    <Col item xs={12} md={6} mt={3}>
                                        <label className="text-xs">End Date</label>
                                        <DatePicker
                                            className="form-control mt-1"
                                            onChange={(date) => { setEndDate(date); setEndDateError(null) }}
                                            value={endDate}
                                            minDate={startDate}
                                            format="dd-MMM yyyy"
                                        />
                                        <FormHelperText error={endDateError}>{endDateError}</FormHelperText>
                                    </Col>
                                </Row>
                            </Box>
                            <Box mt={3}>
                                <Button size="small" onClick={() => checkValidation()}>Create Series</Button>
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel className="box-container p-1" value={createValue} index={1}>
                        <Box>
                            <Typography variant="h6" component="div">
                                Series Details
                            </Typography>
                            <Box component="form" noValidate autoComplete="off" >
                                <Row>
                                    <Col item xs={12} md={12} >
                                        <label className="text-xs">Series Name</label>
                                        <TextField
                                            className="form-control mt-1"
                                            id="outlined-basic"
                                            label="Series Name"
                                            variant="outlined"
                                            name="entertainmentSeriesName"
                                            onChange={(e) => { setEntSeriesName(e.target.value); setEntSeriesNameError(null) }}
                                            value={entSeriesName}
                                            error={entSeriesNameError}
                                            helperText={entSeriesNameError}
                                            autoComplete="off"
                                        />
                                    </Col>
                                </Row>
                            </Box>
                            <Box mt={3}>
                                <Button size="small" onClick={() => checkValidation()}>Create Series</Button>
                            </Box>
                        </Box>
                    </TabPanel>

                </Box>
            </Modal>


            <Footer />
        </DashboardLayout>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




export default ViewSeries;
