import React, { useState } from "react"
import { Container, Card, Box, TextField, Grid, CardContent, Typography, } from "@mui/material";

import 'pages/common.css'

// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import { Button, Row, Col } from 'react-bootstrap';
import { passwordValidator } from "Validation";
import API from "apiConfig";

function ChangePassword() {
    const [passwordDetails, setPasswordDetails] = useState({
        currentPwd: null, newPwd: null,
        confirmPwd: null
    });
    const [errorDetails, setErrorDetails] = useState({
        currentPwd: null, newPwd: null,
        confirmPwd: null
    });

    const handleInputChange = (e) => {
        setPasswordDetails({ ...passwordDetails, [e.target.name]: e.target.value });
        setErrorDetails({ ...errorDetails, [e.target.name]: null });
    }

    const checkValidation = () => {
        let currendPwdError = passwordValidator(passwordDetails.currentPwd);
        let newPwdError = passwordValidator(passwordDetails.newPwd);
        let confirmPwdError = passwordDetails.newPwd !== passwordDetails.confirmPwd ? "New Password and Confirm Password most be same" : passwordValidator(passwordDetails.confirmPwd);
        if (currendPwdError || newPwdError || confirmPwdError) {
            setErrorDetails({
                ...errorDetails,
                currentPwd: currendPwdError,
                newPwd: newPwdError,
                confirmPwd: confirmPwdError
            })
            return;
        }
        else {
            updatePassword();
        }
    }

    //function to update password
    const updatePassword = async () => {
        let dataToSend = {
            "current_password": passwordDetails.currentPwd,
            "new_password": passwordDetails.newPwd,
            "confirm_password": passwordDetails.confirmPwd
        }
        console.log("dataToSend", dataToSend);
        const response = await API.post('login/change_password', dataToSend);
        if (response.data.status) {
            alert(`${response.data.message}`);
            window.location.reload();
        }
        else {
            alert(`${response.data.message}`);
            console.log("Error")
        }
    }
    return (
        <DashboardLayout >
            <Box>
                <DashboardNavbar />

                <Box mb={2} />
                <Box mt={3} mb={3}>
                    <Grid >
                        <Grid item xs={12} md={12} xl={12}>
                            <Card >
                                <Box>
                                    <CardContent>


                                        <Typography pt={2} variant="h4" component="div" className="primary-text">
                                            Change Password
                                        </Typography>

                                        <Box className="box-container">

                                            <Box component="form" noValidate autoComplete="off" >
                                                <Row>
                                                    <Col item xs={12} md={6} mt={3}>
                                                        <label className="text-xs">Current Password</label>
                                                        <TextField
                                                            type="text"
                                                            className="form-control mt-1"
                                                            id="outlined-basic"
                                                            label="Current Password"
                                                            variant="outlined"
                                                            name="currentPwd"
                                                            onChange={handleInputChange}
                                                            value={passwordDetails.currentPwd}
                                                            error={errorDetails.currentPwd}
                                                            helperText={errorDetails.currentPwd}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col item xs={12} md={6} mt={3}>
                                                        <label className="text-xs">New Password</label>
                                                        <TextField
                                                            type="email"
                                                            className="form-control mt-1"
                                                            id="outlined-basic"
                                                            label="New Password"
                                                            variant="outlined"
                                                            name="newPwd"
                                                            onChange={handleInputChange}
                                                            value={passwordDetails.newPwd}
                                                            error={errorDetails.newPwd}
                                                            helperText={errorDetails.newPwd}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col item xs={12} md={6} mt={3}>
                                                        <label className="text-xs">Confirm New Password</label>
                                                        <TextField
                                                            type="text"
                                                            className="form-control mt-1"
                                                            id="outlined-basic"
                                                            label="Confirm New Password"
                                                            variant="outlined"
                                                            name="confirmPwd"
                                                            onChange={handleInputChange}
                                                            value={passwordDetails.confirmPwd}
                                                            error={errorDetails.confirmPwd}
                                                            helperText={errorDetails.confirmPwd}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Box mt={3}>
                                                    <Button size="small" onClick={() => checkValidation()}>Submit</Button>
                                                </Box>
                                            </Box>
                                        </Box>


                                        <hr />
                                    </CardContent>

                                </Box>
                            </Card>

                        </Grid>
                    </Grid>
                </Box>
                <Box pt={2} px={2} lineHeight={1.25}>

                </Box>
                <Footer />
            </Box>
        </DashboardLayout>
    );
}

export default ChangePassword;
